import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENTS
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import PostLink from "../components/PostCard";
import JsonLD from "../components/Json/IndexJsonLd";
import PostContainer from "../components/PostsContainer";

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const siteTitle = data.site.siteMetadata.title;
    const siteUrl = data.site.siteMetadata.siteUrl;

    return (
      <Layout title={siteTitle} location={this.props.location}>
        <Seo pageTitle="" pageDescription="" location={this.props.location} />
        <Helmet>
          <link rel="canonical" href={siteUrl} />
        </Helmet>
        <JsonLD />
        <PostContainer>
          {posts.map((edge) => {
            return <PostLink key={edge.node.id} post={edge.node} />;
          })}
        </PostContainer>
      </Layout>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            path
            title
            category
            thumbnail
          }
          timeToRead
        }
      }
    }
  }
`;
