import React from "react";
import { Reveal } from "react-genie";

import styled from "styled-components";

const Skill = (props) => {
  const Skill = styled.div`
    display: inline-block;
    position: relative;
    text-align: center;

    p {
      color: #333;
      display: inline-block;
      font: bold 12px "Arial", sans-serif;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      height: 100px;
      transform: rotate(270deg); /* 始点の調整 */
      width: 100px;
    }

    .active .circle {
      animation: 0.8s glow forwards;
    }

    .circle {
      fill: none;
      stroke-dasharray: 500px;
      stroke-dashoffset: ${props.level === 4
        ? "0"
        : props.level === 3
        ? "125px"
        : props.level === 2
        ? "250px"
        : "375px"}; /* 余白部分（0~500） */
      stroke-linecap: butt; /* 切り口を直線に */
      stroke-width: 10px;
      &.html {
        stroke: #e44d26;
      }
      &.sass {
        stroke: #c76493;
      }
      &.photoshop {
        stroke: #32c5f0;
      }
      &.php {
        stroke: #536290;
      }
      &.wordpress {
        stroke: #217296;
      }
      &.rails {
        stroke: #ab0f01;
      }
      &.typescript {
        stroke: #046cbc;
      }
      &.express {
        stroke: #333;
      }
      &.react {
        stroke: #2a2c2e;
      }
      &.reactnative {
        stroke: #2a2c2e;
      }
      &.gatsby {
        stroke: #522b81;
      }
      &.next {
        stroke: #000;
      }
      &.vue{
        stroke: #3fba84;
      }
      &.rdb{
        stroke: #035c82;
      }
      &.aws{
        stroke: #ef8d05;
      }
      &.gcp{
        stroke: #3f7de7;
      }
      &.illustrator {
        stroke: #f79604;
      }
      &.blender {
        stroke: #ee9a34;
      }
      &.figma {
        stroke: #333;
      }
      &__bg {
        fill: none;
        stroke: #efefef;
        stroke-width: 10px;
      }
    }

    @keyframes glow {
      0% {
        stroke-dashoffset: 500px;
      }
      40% {
        stroke-dashoffset: 375px;
      }
    }
  `;

  return (
    <Skill>
      <p>{props.title}</p>
      <Reveal animation="active" delay="1000">
        <svg viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
          <circle cx="90" cy="90" r="80" className="circle__bg" />
          <circle cx="90" cy="90" r="80" className={`circle ${props.title.toLowerCase()}`} />
        </svg>
      </Reveal>
    </Skill>
  );
};

export default Skill;
