import React from "react";

import styled from "styled-components";

import ContentList from "./ContentList";


const ContentLists = (props) => {



  const Wrap = styled.main`
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100%;
  max-width: 760px;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 50px 10px 0;
  }
  @media (min-width: 769px) {
    padding: 100px 15px 0;
  }
  position: relative;
  width: 100%;
  z-index: 3;
  @media (max-width: 768px) {
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.19);
    margin-top: calc( 500px - 100px );
  }
  @media (min-width: 769px) {
    margin-top: 480px;
    border-left: 1px solid #e1e8ed;
    border-right: 1px solid #e1e8ed;
  }
  @media (min-width: 790px) {
    border-left: 1px solid #e1e8ed;
    border-right: 1px solid #e1e8ed;
  }

  .border {
    background: #e1e8ed;
    border-radius: 30px;
    content: "";
    display: inline-block;
    height: 5px;
    left: 50%;
    position: absolute;
    right: 0;
    top: 15px;
    transform: translateX(-50%);
    width: 90px;
  }

  .list {
    margin-bottom: 30px;
  }
`;


  return (
    <Wrap>
      <div id="content" className="border"></div>
      <ContentList {...props} />
    </Wrap>
  );
};

export default ContentLists;
