import React, { useEffect } from "react";
import styled from "styled-components";

const Adsense = ({ slot = "6773313483", format = "auto", path }) => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, [path]);

  console.log(path);

  const Wrap = styled.div`
    /* overflow-x: scroll; windowをリサイズしたときに横幅はみ出る問題解決 */
    width: 100%;
  `;

  return (
    <Wrap key={path}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3468709623041812"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive="true"
      ></ins>
    </Wrap>
  );
};

export default Adsense;
