import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENTS
import Seo from "../components/Seo";
import Bio from "../components/Bio";
import Tags from "../components/Tags";
import Layout from "../components/Layout";
import Adsense from "../components/Adsense";
import TopButton from "../components/TopButton";
import PostJsonLD from "../components/Json/PostJsonLd";
import RelatedPosts from "../components/RelatedPosts";
import ShareButtons from "../components/SideShareButtons";
import SideShareButtons from "../components/ShareButtons";
// CSS
import styled from "styled-components";
import prismStyle from "../styles/prism";
import postTocStyle from "../styles/postToc";
import postShapeStyle from "../styles/postShape";
import postContentStyle from "../styles/postContent";
import postEyeCatchStyle from "../styles/postEyecatch";
import blockWrapperStyle from "../styles/blockWrapper";

// ICON
import pen from "../icon/pen.svg";
import refresh from "../icon/refresh.svg";
import hourglass from "../icon/hourglass2.svg";

const mainWidth = (props) => props.theme.container.main;

const PostWrapper = styled.article`
  background: #fff;
  line-height: 1.5;
  margin: auto;
  max-width: ${mainWidth};
  padding: 0;
  position: relative;
  ${prismStyle}
  @media(min-width: 1030px) {
    margin-inline-start: 0;
    padding-inline-start: 80px; /* side share button */
  }
`;

const EyeCatch = styled.div`
  ${blockWrapperStyle}
  ${postEyeCatchStyle}
`;

const ShapeAnimation = styled.span`
  ${postShapeStyle}
`;

const PostContent = styled.div`
  ${blockWrapperStyle}
  ${postContentStyle}
  ${postTocStyle}
`;

const Write = styled.time`
  &::before {
    background: 0 0 / contain url(${pen}) no-repeat;
    content: "";
    display: inline-block;
    height: 1em;
    margin-right: 5px;
    vertical-align: middle;
    width: 1em;
  }
`;

const Modify = styled.time`
  &::before {
    background: 0 0 / contain url(${refresh}) no-repeat;
    content: "";
    display: inline-block;
    height: 1em;
    margin-right: 5px;
    vertical-align: middle;
    width: 1em;
  }
`;

const TimeToRead = styled.span`
  &::before {
    background: 0 0 / contain url(${hourglass}) no-repeat;
    content: "";
    display: inline-block;
    height: 1em;
    margin-right: 5px;
    vertical-align: middle;
    width: 1em;
  }
`;

const BlogPostTemplate = (props) => {
  const { markdownRemark } = props.data;
  const { relatedPosts } = props.pageContext;
  const { frontmatter, html, timeToRead, fields, excerpt } = markdownRemark;
  const siteTitle = props.data.site.siteMetadata.title;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const pageDescription = frontmatter.metaDescription || excerpt;
  // DATE
  const date = frontmatter.date;
  const modifiedDate = fields.gitAuthorTime;
  // PATH
  const location = props.location;

  return (
    <Layout title={siteTitle} location={location}>
      <Seo
        pageTitle={frontmatter.title}
        pageDescription={pageDescription}
        thumbnail={frontmatter.thumbnail ? frontmatter.thumbnail : ""}
        location={location}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl + location.pathname}`} />
      </Helmet>
      <PostJsonLD
        title={frontmatter.title}
        description={pageDescription}
        date={date}
        modifiedDate={modifiedDate}
        url={location.href}
        categoryPath={frontmatter.category}
      />
      <PostWrapper>
        {!frontmatter.thumbnail && (
          <EyeCatch>
            <h1>{frontmatter.title}</h1>
            <small>
              {modifiedDate === date ? (
                <Write>{date}</Write>
              ) : (
                <>
                  <Write>{date}</Write>
                  <Modify>{modifiedDate}</Modify>
                </>
              )}
              <TimeToRead>{timeToRead}分</TimeToRead>
            </small>
            <ShapeAnimation />
            <ShapeAnimation className="circle" />
            <ShapeAnimation className="triangle" />
            <ShapeAnimation />
            <ShapeAnimation className="circle" />
            <ShapeAnimation className="triangle" />
          </EyeCatch>
        )}
        {!!frontmatter.thumbnail && (
          <EyeCatch
            style={{
              backgroundImage: `url(/assets/eyeCatch/${frontmatter.thumbnail})`,
            }}
          >
            <h1>{frontmatter.title}</h1>
            <small>
              {modifiedDate === date ? (
                <Write>{date}</Write>
              ) : (
                <>
                  <Write>{date}</Write>
                  <Modify>{modifiedDate}</Modify>
                </>
              )}
              <TimeToRead>{timeToRead}分</TimeToRead>
            </small>
          </EyeCatch>
        )}
        <Bio />
        <Adsense path={location.pathname} />
        <PostContent dangerouslySetInnerHTML={{ __html: html }} />
        <Tags tags={frontmatter.tags} />
        <Adsense path={location.pathname} />
        <SideShareButtons path={frontmatter.path} title={frontmatter.title} />
        <ShareButtons path={frontmatter.path} title={frontmatter.title} />
        <RelatedPosts posts={relatedPosts} />
        <TopButton />
      </PostWrapper>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        path
        title
        tags
        category
        thumbnail
        metaDescription
      }
      excerpt
      timeToRead
      fields {
        gitAuthorTime(formatString: "YYYY-MM-DD")
      }
    }
  }
`;
