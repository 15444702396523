import React from "react";

import styled from "styled-components";
import "typeface-quicksand";

import Skill from "./Skill";
import Title from "./Title";

const Wrap = styled.section`
  background: #e1e8ed;
  margin-bottom: -10px;
  margin-top: -10px;
  padding-top: 60px;
`;
const Grid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  margin: auto;
  max-width: 768px;
`;

const Skills = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#e1e8ed"
          fillOpacity="1"
          d="M0,224L34.3,197.3C68.6,171,137,117,206,112C274.3,107,343,149,411,192C480,235,549,277,617,256C685.7,235,754,149,823,144C891.4,139,960,213,1029,234.7C1097.1,256,1166,224,1234,202.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <Wrap>
        <Title title="Skills" />
        <Grid>
          {props.nodes.map((skill) => {
            return <Skill {...skill} key={skill.title} />;
          })}
        </Grid>
      </Wrap>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#e1e8ed"
          fillOpacity="1"
          d="M0,288L34.3,261.3C68.6,235,137,181,206,170.7C274.3,160,343,192,411,202.7C480,213,549,203,617,197.3C685.7,192,754,192,823,170.7C891.4,149,960,107,1029,90.7C1097.1,75,1166,85,1234,112C1302.9,139,1371,181,1406,202.7L1440,224L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
        ></path>
      </svg>
    </>
  );
};

export default Skills;
