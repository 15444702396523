import React from "react";
import { Link } from "gatsby";

//IMG
import link from "../icon/link.svg";
import avator from "../icon/sai.svg";
import iconTw from "../icon/socialIcon/twitter.svg";
// import iconIn from "../icon/socialIcon/instagram.svg";
import iconGh from "../icon/socialIcon/github.svg";

//CSS
import styled from "styled-components";
import blockWrapperStyle from "../styles/blockWrapper";

const BioTag = styled.div`
  ${blockWrapperStyle}
  display: flex;
  justify-content: space-between;
  position: relative;
  &::before {
    background: #e1e8ed;
    bottom: 0;
    content: "";
    height: 2px;
    margin: auto;
    max-width: calc(100% - 30px);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  img {
    height: 25px;
    width: 25px;
  }
`;

const BioHeader = styled.header`
  a {
    align-items: center;
    background: #fff;
    color: #333;
    display: flex;
    padding-right: 20px;
    position: relative;
    z-index: 2;
    & > img {
      border: 2px solid #e1e8ed;
      border-radius: 50%;
      margin-right: 10px;
    }
    small {
      img {
        height: 13px;
        margin-left: 3px;
        width: 13px;
      }
    }
  }
`;

const BioSocial = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  padding-left: 20px;
  z-index: 2;
  a {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  img {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    vertical-align: middle;
    &:hover {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }
`;

const Bio = () => {
  return (
    <BioTag id="bio">
      <BioHeader>
        <Link to="/about">
          <img src={avator} alt="アイコン" />
          <small>
            クロサイくん
            <img src={link} alt="アイコン" />
          </small>
        </Link>
      </BioHeader>
      <BioSocial>
        <a
          href="https://twitter.com/9631kunn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iconTw} alt="twitter_icon" title="Twitterでフォロー" />
        </a>
        {/* <a
          href="https://instagram.com/9631kunn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iconIn} alt="instagram_icon" title="Instagramでフォロー" />
        </a> */}
        <a
          href="https://github.com/9631kunn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iconGh} alt="github_icon" title="Githubでフォロー" />
        </a>
      </BioSocial>
    </BioTag>
  );
};

export default Bio;
