import { keyframes } from "styled-components";

const fadeIn = keyframes`
  50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: none;
  }
`;

export default fadeIn;
