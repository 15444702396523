import { css } from "styled-components";

const prismStyle = css`
  /**
  * okaidia theme for JavaScript, CSS and HTML
  * Loosely based on Monokai textmate theme by http://www.monokai.nl/
  * @author ocodia
  */

  .gatsby-highlight {
    margin-top: 0;
    margin-bottom: 20px;
    max-height: 200px;
    overflow: hidden;
    position: relative;
    &.toggleable {
      max-height: none;
      .toggleable__filter,
      .toggleable__btn {
        display: none;
      }
    }
  }

  /* codeBlock */
  .toggleable__filter {
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 17%, 0.1) 5%,
      hsla(0, 0%, 6%, 0.6),
      hsla(0, 0%, 2%, 0.8)
    );
    bottom: 0;
    display: inline-block;
    height: 100px;
    position: absolute;
    width: 100%;
  }

  .toggleable__btn {
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.39);
    bottom: 10px;
    cursor: pointer;
    font-size: 0.75em;
    left: 0;
    margin: auto;
    max-width: 280px;
    min-width: 130px;
    padding: 0.5em;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1;
    &::before {
      content: "＋";
      display: inline-block;
      font-weight: bold;
      left: 10px;
      position: absolute;
    }
  }

  li > code[class*="language-"],
  p > code[class*="language-"],
  p > pre[class*="language-"] {
    background: #fff;
    border: 1px solid #e1e8ed;
    border-radius: 3px;
    color: #444;
    font-family: monospace;
    margin: 0 0.25em;
    padding: 0.1em 0.35em;
  }

  /* Code blocks */
  pre[class*="language-"] {
    border-radius: 0 0.3em 0.3em 0.3em;
    font-size: 0.8em;
    margin: 0.5em 0;
    overflow: auto;
    padding: 1em;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #333;
    color: #fff;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0 0.3em 0.3em 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: slategray;
  }

  .token.punctuation {
    color: #f8f8f2;
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #f92672;
  }

  .token.boolean,
  .token.number {
    color: #ae81ff;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #a6e22e;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: #f8f8f2;
  }

  .token.atrule,
  .token.attr-value,
  .token.function {
    color: #e6db74;
  }

  .token.keyword {
    color: #66d9ef;
  }

  .token.regex,
  .token.important {
    color: #fd971f;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
  /* highlight */
  .gatsby-highlight-code-line {
    background: #222;
    border-left: 0.5em solid #444;
    color: #f8f8f2;
    display: block;
    margin-left: -1em;
    margin-right: -1em;
    padding-right: 1em;
    padding-left: 0.5em;
  }
  /* fileName */
  .gatsby-code-title {
    background: #333;
    border-radius: 8px 8px 0 0;
    color: #fff;
    display: table;
    font-family: "Trebuchet MS", sans-serif;
    font-size: 0.8em;
    line-height: 0.2;
    margin-bottom: -0.5em;
    padding: 1em 1.25em;
  }
`;

export default prismStyle;
