import React from "react";
import styled from "styled-components";
import "typeface-quicksand";

import icon from "../../icon/categoryIcon/pinata.svg";

const FirstView = () => {
  const Wrap = styled.section`
    background: #e1e8ed;
    border-radius: 0 0 50% 50% / 0 0 20% 20%;
    height: 500px;
    padding-top: 60px;
    position: relative;
    z-index: 0;
  `;

  const Inner = styled.div`
    align-items: center;
    display: grid;
    height: 100%;
    margin: auto;
    max-width: 768px;
    padding: 80px 0;
    grid-template-columns: 1fr 100px;
    width: 95%;
  `;

  const Block = styled.div`
    h1 {
      font-family: "Quicksand", sans-serif;
      font-size: 28px;
      @media (min-width: 480px) {
        font-size: 38px;
      }
      @media (min-width: 769px) {
        font-size: 45px;
      }
    }
    picture {
      position: relative;
      &::after {
        background: #b3bfc7;
        border-radius: 50%;
        bottom: 0;
        content: "";
        height: 40px;
        left: 5px;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }
  `;

  return (
    <Wrap>
      <Inner>
        <Block>
          <h1>Welcome To My Portfolio</h1>
        </Block>
        <Block>
          <picture>
            <img src={icon} alt="アイコン" />
          </picture>
        </Block>
      </Inner>
    </Wrap>
  );
};

export default FirstView;
