import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// CSS
import blockWrapperStyle from "../styles/blockWrapper";

const Wrapper = styled.div`
  ${blockWrapperStyle}
  h3 {
    font-weight: bold;
    margin-bottom: 1em;
    position: relative;
    text-align: center;
    span {
      background: #fff;
      padding: 0.5em 1em;
      position: relative;
    }
    &::before {
      background: #e1e8ed;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      margin: 0 auto;
      max-width: 768px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  ul {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    list-style: none;
    a {
      border-radius: 3px;
      color: #2f353d;
      display: block;
      padding: 0.5em;
      transition: 0.3s;
      &:hover {
        background: #efefef;
      }
      @media (max-width: 479px) {
        border-bottom: 2px dashed #e1e8ed;
        padding-bottom: 0.5em;
      }
    }
    @media (min-width: 480px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const RelatedPostCard = ({ node }) => {
  const title = node.frontmatter.title || node.frontmatter.path;

  return (
    <li>
      <Link to={node.frontmatter.path}>
        <h4>{title}</h4>
      </Link>
    </li>
  );
};

const RelatedPosts = ({ posts }) => {
  if (!posts.length) return null;
  let content = [];

  posts.forEach((post) => {
    content.push(
      <RelatedPostCard key={post.node.frontmatter.path} node={post.node} />
    );
  });
  return (
    <Wrapper id="relatedPosts">
      <h3>
        <span>こちらもオススメ</span>
      </h3>
      <ul>{content}</ul>
    </Wrapper>
  );
};

export default RelatedPosts;
