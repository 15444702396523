import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENT
import Seo from "../components/Seo";
import Header from "../components/Header";
import FirstView from "../components/Portfolio/FirstView";
import Poem from "../components/Portfolio/Poem";
import Works from "../components/Portfolio/Works";
import Skills from "../components/Portfolio/Skills";
import PortfolioForm from "../components/Portfolio/PortfolioForm";
import Footer from "../components/Footer";

// CSS
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/global";
import theme from "../styles/themes/theme";

const Portfolio = ({ data, location }) => {
  const siteUrl = data.site.siteMetadata.siteUrl;
  const works = data.allWork;
  const skills = data.allSkill;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Seo
        pageTitle="9631kunn's portfolio"
        pageDescription="なんの変哲もないポートフォリオ"
        thumbnail="portfolio.png"
        location={location}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}/portfolio`} />
      </Helmet>
      <Header location={location} />
      <FirstView />
      <Poem />
      <Works {...works} />
      <Skills {...skills} />
      <PortfolioForm />
      <Footer />
    </ThemeProvider>
  );
};

export default Portfolio;

export const portfolioQuery = graphql`
  query portfolioQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    allWork {
      nodes {
        name
        date
        lang
        description
        more
        url
      }
    }
    allSkill {
      nodes {
        title
        level
      }
    }
  }
`;
