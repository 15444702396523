import { css } from 'styled-components';

const postShapeStyle = css`
  animation: transformShape 10s ease-in forwards infinite;
  border: 1px solid #efefef;
  height: 10px;
  left: 10%;
  position: absolute;
  top: 10%;
  -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%); transform: scale(0) rotate(0deg) translate(-50%, -50%);
  transform-origin: top left;
  width: 10px;
  z-index: 1;
  &:nth-of-type(2n){
    border-color: #999;
  }
  &:nth-of-type(2){
    animation-delay: 1s;
    left: 0%;
    top: 90%;
  }
  &:nth-of-type(3){
    animation-delay: 3s;
    left: 90%;
    top: 0%;
  }
  &:nth-of-type(4){
    animation-delay: 5s;
    left: 40%;
    top: 40%;
  }
  &:nth-of-type(5){
    animation-delay: 7s;
    left: 100%;
    top: 90%;
  }
  &:nth-of-type(6){
    animation-delay: 9s;
    left: 50%;
    top: 70%;
  }
  &.circle{
    border-radius: 50%;
  }
  &.triangle{
    border-bottom: 17.32px solid #999;
    border-left: 10px solid transparent; 
    border-top: none;
    border-right: 10px solid transparent;
  }
  @keyframes transformShape{
    0% {
      opacity: .8;
      -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%); transform: scale(0) rotate(0deg) translate(-50%, -50%);
    }
    50%{
      opacity: .2;
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(20) rotate(960deg) translate(-50%, -50%); transform: scale(20) rotate(960deg) translate(-50%, -50%); 
    }
  }
`;

export default postShapeStyle;