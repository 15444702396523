import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Test from "../components/Firebase/Test";

// COMPONENT
import Header from "../components/Header";
import Footer from "../components/Footer";

// CSS
import { ThemeProvider } from "styled-components";
import theme from "../styles/themes/theme";
import GlobalStyle from "../styles/global";

const FirebaseTest = ({ data, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header location={location} />
      <Test {...data} />
      <Footer />
    </ThemeProvider>
  );
};

export default FirebaseTest;

export const firebaseTestQuery = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    allHimatsubushi {
      nodes {
        id
        title
        content
        url
      }
    }
  }
`;
