import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

//COMPONENTS
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import PostLink from "../components/PostCard";
import PostContainer from "../components/PostsContainer";
import CategoryJsonLd from "../components/Json/CategoryJsonLd";

const CategoryTemplate = (props) => {
  const { data, pageContext } = props;
  const post = data.allMarkdownRemark.edges;
  const siteTitle = props.data.site.siteMetadata.title;
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  const categoryPath = pageContext.category;
  const categoryObject = data.site.siteMetadata.categories.find((cat) => {
    return cat.path === categoryPath;
  });
  const categoryName = categoryObject ? categoryObject.name : "未分類";

  return (
    <Layout title={siteTitle} location={props.location}>
      <Seo pageTitle={categoryName + "の記事一覧"} location={props.location} />
      <Helmet>
        <link rel="canonical" href={siteUrl + "/category/" + categoryPath} />
      </Helmet>
      <CategoryJsonLd categoryName={categoryName} categoryPath={categoryPath} />
      <PostContainer>
        {post.map((edge) => {
          return <PostLink key={edge.node.id} post={edge.node} />;
        })}
      </PostContainer>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query($category: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        categories {
          name
          path
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            path
            title
            category
            thumbnail
          }
          timeToRead
        }
      }
    }
  }
`;
