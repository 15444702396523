import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class CenterMode extends Component {
  render() {
    var settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "30px",
      slidesToShow: 4,
      speed: 500,
    };
    return (
      <Slider {...settings}>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
        <div className="card box-shadow">
          <h3>1</h3>
        </div>
      </Slider>
    );
  }
}
