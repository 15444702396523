import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Image from "../Image";

import styled from "styled-components";

import AgeIcon from "../../icon/himastubushi/age.svg";
import YenIcon from "../../icon/himastubushi/yen.svg";
import CrossIcon from "../../icon/himastubushi/close.svg";
import MissionIcon from "../../icon/himastubushi/mission.svg";
import LocationIcon from "../../icon/himastubushi/location.svg";
import SandClockIcon from "../../icon/himastubushi/hour-glass.svg";

const ContentList = (props) => {
  // GraphQLからのデータ
  const ListData = useStaticQuery(graphql`
    query SearchHimatsubushiData {
      allHimatsubushi {
        nodes {
          id
          title
          imageName
          content
          tag
          price
          place
          purpose
          time
          age
          url
          externalLink {
            text
            url
          }
        }
      }
    }
  `);

  // 表示するリスト
  const [data, setData] = useState([]);

  // 検索処理
  useEffect(() => {
    // 表示するリスト(仮)
    const lists = [];

    ListData.allHimatsubushi.nodes.map((list) => {
      //gatsby-nodeのオブジェクトの値をすべて格納
      const listValues = Object.values(list);
      list.tag.map((t) => listValues.push(t));
      // 絞り込み条件がなければ表示するリスト(仮)にすべて格納、絞り込み条件がありすでにlistsにpushされていなければ格納
      props.dataState.length === 0
        ? lists.push(list)
        : props.dataState.some((e) => {
            // 各条件に一致する値を含んでいなければ処理中断
            const isThere = listValues.indexOf(e) === -1;
            if (isThere) return;
            // 含んでいれば表示リストに追加してsomeループ中断
            lists.push(list);
            return true;
          });

      return;
    }); // END map()

    // 表示するリスト(本物)に格納
    setData(lists);
  }, [props.dataState]); // END useEffect

  const ConditionCountWrap = styled.div`
    border: 1px solid #eee;
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 0.5em 1em;
  `;

  const ConditionCount = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
      background: #efefef;
      border-radius: 30px;
      font-size: 12px;
      margin: 5px;
      padding: 3px 30px 3px 20px;
      position: relative;
      text-align: center;
      button {
        bottom: 0;
        line-height: 12px;
        margin: auto;
        position: absolute;
        right: 8px;
        top: 0;
        img {
          height: 7px;
          width: 7px;
        }
      }
    }
  `;

  const ListWrap = styled.article`
    @media (max-width: 980px) {
      padding-bottom: 52px;
    }
    section {
      > * {
        margin-bottom: 20px;
      }
      @media (min-width: 769px) {
        margin-bottom: 100px;
        padding-bottom: 100px;
      }
      @media (max-width: 768px) {
        margin-bottom: 50px;
        padding-bottom: 50px;
      }
      &:not(:last-of-type) {
        position: relative;
        &::after {
          border-bottom: 2px solid #e1e8ed;
          bottom: -1px;
          content: "";
          display: inline-block;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          width: 50%;
        }
      }
      header {
        > * {
          margin-bottom: 10px;
        }
        h2 {
          font-size: 20px;
          line-height: 25px;
          padding-left: 30px;
          position: relative;
          word-break: break-all;
          span {
            background: #666;
            border-radius: 50%;
            color: #fff;
            font: bold 11px "Avenir", "Arial Black", "Arial", sans-serif;
            height: 25px;
            left: 0;
            line-height: 25px;
            position: absolute;
            text-align: center;
            width: 25px;
          }
        }
        .els {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          list-style: none;
          margin-bottom: 5px;
          li {
            display: flex;
            height: 16px;
            margin-bottom: 5px;
            &:not(:last-of-type) {
              margin-right: 15px;
            }
            span {
              color: #666;
              font-size: 11px;
              line-height: 14px;
            }
            img {
              height: 14px;
              margin-right: 5px;
              width: 14px;
            }
          }
        }
        .eyecatch {
          border-radius: 7px;
          @media (max-width: 768px) {
            height: 100px;
          }
          @media (min-width: 769px) {
            height: 200px;
          }
        }
      }
      .content {
        line-height: 1.75;
      }
      .links {
        list-style: none;
        li {
          margin-bottom: 8px;
          padding-left: 30px;
          position: relative;
          &::before {
            color: #666;
            content: "👉";
            display: inline-block;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .tags {
        color: #aaa;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        line-height: 14px;
        list-style: none;
        li {
          &::before {
            content: "#";
            display: inline-block;
            line-height: 14px;
          }
          &:not(:last-of-type)::after {
            content: ",";
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
  `;

  return (
    <>
      {props.dataState.length !== 0 && (
        <ConditionCountWrap>
          <ConditionCount>
            {props.dataState.map((e) => (
              <li key={e}>
                {e}
                <button
                  onClick={() => {
                    props.dataHandleChange(e);
                  }}
                >
                  <img src={CrossIcon} />
                </button>
              </li>
            ))}
          </ConditionCount>
        </ConditionCountWrap>
      )}
      <ListWrap>
        {data.map((d, index) => {
          return (
            <section key={index}>
              <header>
                <h2>
                  <span>{index + 1}</span>
                  {d.title}
                </h2>
                <ul className="els">
                  <li>
                    <img src={YenIcon} alt="円アイコン" />
                    <span>{d.price}</span>
                  </li>
                  <li>
                    <img src={MissionIcon} alt="目的アイコン" />
                    <span>{d.purpose}</span>
                  </li>
                  <li>
                    <img src={LocationIcon} alt="場所アイコン" />
                    <span>{d.place}</span>
                  </li>
                  <li>
                    <img src={SandClockIcon} alt="時計アイコン" />
                    <span>{d.time}</span>
                  </li>
                  <li>
                    <img src={AgeIcon} alt="年齢層アイコン" />
                    <span>{d.age}</span>
                  </li>
                </ul>
                {d.imageName && (
                  <Image
                    className="eyecatch"
                    src={`himatsubushi/${d.imageName}`}
                  />
                )}
              </header>
              <p className="content">{d.content}</p>
              {d.externalLink.length !== 0 && (
                <ul className="links">
                  {d.externalLink.map((link) => (
                    <li key={link.url}>
                      <a href={link.url}>{link.text}</a>
                    </li>
                  ))}
                </ul>
              )}
              <ul className="tags">
                {d.tag.map((t) => (
                  <li key={t}>{t}</li>
                ))}
              </ul>
            </section>
          );
        })}
      </ListWrap>
    </>
  );
};

export default ContentList;
