import { css } from 'styled-components';

const postEyeCatchStyle = css`
  align-content: center;
  animation: changeBackground 10s ease-in-out infinite;
  background-color: #999;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  display: grid;
  grid-gap: 15px;
  margin: 15px auto;
  max-width: calc( 768px - 30px ); /* 他のブロックと横幅揃えるために */
  min-height: 240px; /* アイキャッチがない時にも最低限の高さ指定 */
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  @media(min-width: 745px){
    border-radius: 5px;
  }
  &:before {
    content: "";
    background: rgba(0,0,0,.79);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 2;
  }
  h1{
    font-size: 1.25em;
    text-shadow: 0 1px 3px rgba(0,0,0,.39), 0 2px 4px rgba(0,0,0,.39), 0 4px 8px rgba(0,0,0,.39), 0 4px 12px rgba(0,0,0,.49);
  }
  small{
    color: rgba(255,255,255,0.8);
    margin-bottom: 0;
    > *:not(:last-child){
      margin-right: 1em;
    }
  }
  @keyframes changeBackground{
    0%{ background-color: #999; }
    10%{ background-color: #888; }
    20%{ background-color: #666; }
    30%{ background-color: #444; }
    40%{ background-color: #222; }
    50%{ background-color: #000; }
    60%{ background-color: #222; }
    70%{ background-color: #444; }
    80%{ background-color: #666; }
    90%{ background-color: #888; }
    100%{ background-color: #999; }
  }
`;

export default postEyeCatchStyle;
