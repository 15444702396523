import React from "react";
import {
  Stage,
  Layer,
  Rect,
  Text,
  Circle,
} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Circle";
import "konva/lib/shapes/Line";

import ShareButtons from "../ShareButtons";

import styled from "styled-components";

import Download from "../../icon/download.svg";

const Wrap = styled.div`
  width: 500px;

  .box {
    border: 2px solid #e6edf3;
    border-radius: 7px;
    margin-top: 30px;
    padding: 20px;

    p {
      align-items: center;
      display: flex;
      font-weight: bold;
      justify-content: center;
      margin-bottom: 20px;
      opacity: 0;
      transition: 0.5s;

      img {
        height: 30px;
        margin-right: 15px;
        width: 30px;
      }
    }

    &.active {
      p {
        opacity: 1;
      }
    }
  }
`;

const Canvas = (props) => {
  return (
    <Wrap>
      <Stage width={500} height={300}>
        <Layer>
          {/* 背景 */}
          <Rect width={500} height={300} fill={props.backgroundColor} />
          {/* ウィンドウ */}
          <Rect
            x={50}
            y={50}
            width={400}
            height={200}
            cornerRadius={7}
            fill={props.darkmode ? "#222" : "#E6EDF3"}
            shadowOpacity={props.darkmode ? 0.9 : 0.5}
            shadowBlur={10}
          />
          {/* ボタン */}
          <Circle x={70} y={70} width={10} height={10} fill="#FF7676" />
          <Circle x={90} y={70} width={10} height={10} fill="#FFC41E" />
          <Circle x={110} y={70} width={10} height={10} fill="#46E26B" />
          {/* ダラーマーク */}
          {props.terminal && (
            <Text
              x={70}
              y={100}
              fontFamily={"'Arial'"}
              fontSize={16}
              lineHeight={1.8}
              fill={props.darkmode ? "#39AD36" : "#60AF64"}
              text="$"
            />
          )}
          {/* テキスト */}
          <Text
            // 位置
            x={props.terminal ? 90 : 70}
            y={100}
            // 装飾
            fontFamily={"'Arial'"}
            fontSize={16}
            fill={props.darkmode ? "#fff" : "#545454"}
            lineHeight={1.8}
            letterSpacing={1}
            // 折り返し
            wrap="char"
            width={props.terminal ? 320 : 360}
            //  テキスト
            text={props.text}
          />
        </Layer>
      </Stage>
      <div className={`box ${props.text ? "active" : ""}`}>
        <p>
          <img src={Download} alt="" />
          <span>Save It && Bookmark This Page !!</span>
        </p>
        <ShareButtons title={props.title} path={props.path} />
      </div>
    </Wrap>
  );
};

export default Canvas;
