import React from "react";

// COMPONENT
import Seo from "../components/Seo";
import Header from "../components/Header";
import Category from "../components/Category";
import MainArea from "../components/TeExamStudy/MainArea";
import Footer from "../components/Footer";

// CSS
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/global";
import theme from "../styles/themes/theme";

const TeExamStudy = ({ location }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Seo
      pageTitle="基本情報技術の勉強時間"
      pageDescription="勉強時間の記録です"
      thumbnail="portfolio.png"
      location={location}
    />
    <Header location={location} />
    <Category />
    <MainArea />
    <Footer />
  </ThemeProvider>
);

export default TeExamStudy;
