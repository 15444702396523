import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENTS
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Form from "../components/Form";

// CSS
import styled from "styled-components";
import postContentStyle from "../styles/postContent";
import blockWrapperStyle from "../styles/blockWrapper";

// ICON
import iconAd from "../icon/address.svg";
// import iconMa from "../icon/mail.svg";
import iconTw from "../icon/socialIcon/twitter.svg";
import iconIn from "../icon/socialIcon/instagram.svg";
import iconGh from "../icon/socialIcon/github.svg";
// import iconRe from '../icon/socialIcon/resume.svg';

const Title = styled.h2`
  font-size: 1em;
`;

const PostContent = styled.div`
  ${blockWrapperStyle}
  ${postContentStyle}
`;

const Profile = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  padding-left: 0 !important;
  @media (max-width: 380px) {
    grid-template-columns: 1fr;
  }
  li,
  a {
    align-items: center;
    color: #333;
    display: flex;
  }
  img:not(.emoji) {
    border: none;
    box-shadow: none;
    height: 30px;
    margin-right: 0.5em;
    width: 30px;
  }
`;

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const siteUrl = data.site.siteMetadata.siteUrl;

    return (
      <Layout title={siteTitle} location={this.props.location}>
        <Seo pageTitle="クロサイについて" pageDescription="ネット上トップレベルの誰得ページ" location={this.props.location} />
        <Helmet>
          <meta name="robots" content="noindex" />
          <link rel="canonical" href={`${siteUrl}/about`} />
        </Helmet>
        <PostContent>
          <Title>管理人・クロサイについて</Title>
          <div className="custom-block balloon">
            <div className="custom-block-body">
              <p>
                個人開発が好きなWebエンジニア(H6年生)。ブログ3年目に突入（2018/09~）しました。過去のブログは数十万で売却済み。当ブログは月間5万PVくらい。会社では複数ある自社メディア（合計
                百数十万PV/月）の解析/運用/SEO・MEO施策などのWebマスター的な仕事やWeb開発(PHP/JS)などをのんびりやってます。
              </p>
            </div>
          </div>
          <Profile>
            <li>
              <img className="no__shadow" src={iconAd} alt="アイコン" />
              埼玉県
            </li>
            <li>
              <a href="https://twitter.com/9631kunn">
                <img className="no__shadow" src={iconTw} alt="アイコン" />
                @9631kunn
              </a>
            </li>
            <li>
              <a href="https://github.com/9631kunn">
                <img className="no__shadow" src={iconGh} alt="アイコン" />
                @9631kunn
              </a>
            </li>
            <li>
              <a href="https://instagram.com/9631kunn">
                <img className="no__shadow" src={iconIn} alt="アイコン" />
                @9631kunn
              </a>
            </li>
          </Profile>
          <Title>お問い合わせ</Title>
          <p>
            サイト制作依頼や広告掲載、記事に関する修正などはこちらのフォームから承っております。記事に関する質問のメールがかなり多いのですが多忙につきそういった質問等はお仕事としての場合のみ受け付けております（500円のアマギフなど送っていただければ幸いです）
          </p>
          <Form />
        </PostContent>
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query aboutPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
