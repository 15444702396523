import React from "react";
import { Link } from "gatsby";

import iconHome from "../icon/home.svg";

import styled from "styled-components";
import blockWrapperStyle from "../styles/blockWrapper";

const FooterTag = styled.footer`
  ${blockWrapperStyle}
  color: #666;
  font-weight: bold;
  margin: 20px auto;
  padding: 90px 15px 30px;
  text-align: center;
  a {
    display: inline-block;
    position: relative;
    &:hover {
      text-decoration: none;
    }
    &::after {
      background: #efefef;
      border-radius: 50%;
      bottom: -3px;
      content: "";
      display: inline-block;
      height: 25px;
      left: -5px;
      margin: auto;
      right: -5px;
      position: absolute;
      width: 60px;
      z-index: -1;
    }
    img {
      display: block;
      height: 50px;
      margin: auto;
      width: 50px;
    }
    span {
      display: block;
      text-align: center;
    }
  }
`;

const Footer = () => {
  return (
    <FooterTag id="footer">
      <p>＼ HOME ／</p>
      <Link to="/">
        <img src={iconHome} alt="トップへ戻る" />
      </Link>
    </FooterTag>
  );
};

export default Footer;
