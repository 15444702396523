import { css } from "styled-components";

const searchWrapperStyle = css`
  position: relative;
  img {
    height: 1.25em;
    left: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25em;
    z-index: 2;
  }
  input {
    border: 1px solid #e1e8ed;
    border-radius: 30px;
    box-shadow: none;
    color: #555;
    font-size: 16px; /* iOSのズーム仕様対策 */
    line-height: 1.75;
    outline: none;
    padding-left: 35px;
    transition: 0.3s;
    width: ${(props) => (props.focus ? "200px" : "130px")};
    @media (max-width: 330px) {
      width: ${(props) => (props.focus ? "180px" : "110px")};
    }
    @media (min-width: 480px) {
      width: ${(props) => (props.focus ? "300px" : "180px")};
    }
    &::placeholder {
      color: #ccc;
      text-shadow: none;
    }
  }
`;

export default searchWrapperStyle;
