import React from "react";
import { graphql } from "gatsby";

import GlobalStyle from "../styles/global";
import { ThemeProvider } from "styled-components";
import theme from "../styles/themes/himatsubushi";

import Seo from "../components/Seo";
import MainArea from "../components/Himatsubushi/MainArea";

const Himatsubushi = ({ location }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Seo
      pageTitle="ヒマツ武士"
      pageDescription="世界はこんなにも楽しいことで満ちている。暇な時間を潰すのに打ってつけの暇つぶしを大量に揃えました。コロナ禍で退屈している人、ドライブや仕事中に暇な時間がある人、待ち時間を潰したい人。全ての暇な人が楽しく時間を潰せる手段を見つけられれば幸いです。"
      thumbnail="himatsubushi.png"
      location={location}
    />
    <MainArea />
  </ThemeProvider>
);

export default Himatsubushi;
