import React, { useState } from "react";
import { Link } from "gatsby";

import SwitchBar from "./SwitchBar";

import styled from "styled-components";

import Search from "../../icon/himastubushi/search.svg";
import Back from "../../icon/himastubushi/back.svg";
import Top from "../../icon/himastubushi/top.svg";

const MenuWrap = styled.footer`
  background: #fff;
  bottom: 0;
  display: inline-block;
  height: 50px;
  max-width: 758px;
  padding: 13px 0;
  position: fixed;
  width: 100%;
  z-index: 5;
  &.growing {
    animation: growUp 0.3s 0.2s forwards;
    .first__menu {
      animation: fadeInMenuButton 0.4s forwards;
      display: inline-block;
      opacity: 0;
      transform: translateY(-10px);
      width: 100%;
      &:nth-of-type(1) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(2) {
        animation-delay: 0.55s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.6s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.65s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.7s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.75s;
      }
    }
  }
  @media (min-width: 981px) {
    display: none;
  }
  @keyframes growUp {
    100% {
      height: 390px;
    }
  }
  @keyframes fadeInMenuButton {
    100% {
      opacity: 1;
      transform: none;
    }
  }
`;

const Menu = styled.ul`
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  list-style: none;
  place-items: center;

  img {
    height: 20px;
    width: 20px;
  }
`;

const Line = styled.div`
  background: #e1e8ed;
  bottom: 0;
  display: inline-block;
  height: 52px;
  max-width: 758px;
  position: fixed;
  width: 100%;
  z-index: 4;
  @media (min-width: 981px) {
    display: none;
  }
  &.growing {
    animation: growUpLine 0.5s forwards;
  }

  @keyframes growUpLine {
    100% {
      height: 392px;
    }
  }
`;

const SearchMenuWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: auto;
  max-width: 200px;
`;

const SecondSearchMenuWrap = styled.div`
  animation: fadeInFromRight 0.3s;
  display: grid;
  height: 100%;
  margin: auto;
  max-width: 200px;

  label {
    border: none;
  }

  @keyframes fadeInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
`;

const SpMenu = (props) => {
  const [grow, setGrow] = useState();
  const [menu, setMenu] = useState(null);

  const price = ["無料", "有料"];
  const place = ["屋内", "屋外", "どこでも"];
  const time = ["10分", "1時間", "1日", "1ヶ月以上"];
  const purpose = [
    "笑いたい",
    "泣きたい",
    "楽しみたい",
    "学びたい",
    "眺めたい",
    "稼ぎたい",
    "頑張りたい",
  ];
  const age = ["10〜20代", "30〜50代", "60代以上", "全年齢"];

  return (
    <>
      <MenuWrap className={grow ? "growing" : ""}>
        {/* 絞り込みメニューが選択されていない */}
        {!grow && (
          <Menu>
            <li>
              <Link to="/">
                <img src={Back} alt="" />
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setGrow(true);
                }}
              >
                <img src={Search} alt="" />
              </button>
            </li>
            <li>
              <Link to="#content">
                <img src={Top} alt="" />
              </Link>
            </li>
          </Menu>
        )}
        {/* 絞り込みメニューの条件が選択されていない（第一階層） */}
        {grow && !menu && (
          <SearchMenuWrap>
            <button
              className="first__menu"
              onClick={() => {
                setMenu("価格で絞り込む");
              }}
            >
              価格で絞り込む
            </button>
            <button
              className="first__menu"
              onClick={() => {
                setMenu("場所で絞り込む");
              }}
            >
              場所で絞り込む
            </button>
            <button
              className="first__menu"
              onClick={() => {
                setMenu("時間で絞り込む");
              }}
            >
              時間で絞り込む
            </button>
            <button
              className="first__menu"
              onClick={() => {
                setMenu("目的で絞り込む");
              }}
            >
              目的で絞り込む
            </button>
            <button
              className="first__menu"
              onClick={() => {
                setMenu("対象年齢で絞り込む");
              }}
            >
              対象年齢で絞り込む
            </button>
            <button
              className="first__menu"
              onClick={() => {
                setGrow(false);
              }}
            >
              [ 閉じる ]
            </button>
          </SearchMenuWrap>
        )}
        {/* 絞り込みメニューの条件が選択されている（第二階層） */}
        {grow && menu && (
          <SecondSearchMenuWrap>
            {menu === "価格で絞り込む" && (
              <>
                {price.map((e) => {
                  return (
                    <SwitchBar
                      key={e}
                      title={e}
                      state={props.dataState}
                      onChange={() => {
                        props.dataHandleChange(e);
                      }}
                    />
                  );
                })}
              </>
            )}
            {menu === "場所で絞り込む" && (
              <>
                {place.map((e) => {
                  return (
                    <SwitchBar
                      key={e}
                      title={e}
                      state={props.dataState}
                      onChange={() => {
                        props.dataHandleChange(e);
                      }}
                    />
                  );
                })}
              </>
            )}
            {menu === "時間で絞り込む" && (
              <>
                {time.map((e) => {
                  return (
                    <SwitchBar
                      key={e}
                      title={e}
                      state={props.dataState}
                      onChange={() => {
                        props.dataHandleChange(e);
                      }}
                    />
                  );
                })}
              </>
            )}
            {menu === "目的で絞り込む" && (
              <>
                {purpose.map((e) => {
                  return (
                    <SwitchBar
                      key={e}
                      title={e}
                      state={props.dataState}
                      onChange={() => {
                        props.dataHandleChange(e);
                      }}
                    />
                  );
                })}
              </>
            )}
            {menu === "対象年齢で絞り込む" && (
              <>
                {age.map((e) => {
                  return (
                    <SwitchBar
                      key={e}
                      title={e}
                      state={props.dataState}
                      onChange={() => {
                        props.dataHandleChange(e);
                      }}
                    />
                  );
                })}
              </>
            )}
            <button
              onClick={() => {
                setMenu(null);
              }}
            >
              [ 戻る ]
            </button>
          </SecondSearchMenuWrap>
        )}
      </MenuWrap>
      <Line className={grow ? "growing" : ""} />
    </>
  );
};

export default SpMenu;
