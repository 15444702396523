import { keyframes } from "styled-components";

const glitchBottom = keyframes`
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
`;

export default glitchBottom;
