import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const PostJsonLD = ({
  title,
  description,
  date,
  modifiedDate,
  categoryPath,
  url,
}) => {
  return (
    <StaticQuery
      query={JsonLdPostQuery}
      render={(data) => {
        const { siteUrl, author, categories } = data.site.siteMetadata;

        const publisher = {
          "@type": "Organization",
          name: author,
          logo: {
            "@type": "ImageObject",
            url: `${siteUrl}/assets/sai.png`,
            height: 150,
            width: 150,
          },
        };
        const authorData = {
          "@type": "Person",
          name: author,
          image: `${siteUrl}/assets/sai.png`,
        };
        const jsonLd = {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          mainEntityOfPage: url,
          headline: title,
          image: `${siteUrl}/assets/ogp.png`,
          editor: author,
          url: url,
          datePublished: date,
          dateCreated: date,
          dateModified: modifiedDate,
          description: description,
          author: authorData,
          publisher,
        };

        const categoryObject = categories.find((cat) => {
          return cat.path === categoryPath;
        });

        const categoryName = categoryObject
          ? categoryObject.name
          : categoryPath;

        const jsonBreadCrumbs = {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": siteUrl,
                name: "HOME",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": `${siteUrl}/${categoryPath}`,
                name: categoryName,
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": url,
                name: title,
              },
            },
          ],
        };
        return (
          <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
            <script type="application/ld+json">
              {JSON.stringify(jsonBreadCrumbs)}
            </script>
          </Helmet>
        );
      }}
    />
  );
};

export default PostJsonLD;

const JsonLdPostQuery = graphql`
  query JsonLdPostQuery {
    site {
      siteMetadata {
        siteUrl
        author
        categories {
          name
          path
        }
      }
    }
  }
`;
