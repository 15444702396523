import React from "react";
import { Link } from "gatsby";

// COMPONENTS
import News from "./News";
import Search from "./Search";

// CSS
import styled from "styled-components";

// IMG
import logo from "../icon/logo.svg";

const HeaderTag = styled.header`
  background: transparent;
  backdrop-filter: blur(20px);
  position: fixed;
  transition: 0.5s;
  width: 100%;
  z-index: 99;
  &.hidden {
    transform: translateY(-90px);
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${(props) => props.theme.container.header};
  padding: 15px;
  a:hover {
    text-decoration: none;
  }
  h1,
  h3 {
    color: #333;
    display: flex;
    font-size: 1.3em;
    line-height: 30px;
    /* logo */
    img {
      height: 30px;
      margin-right: 3px;
      width: 110px;
    }
  }
`;

const Header = ({ location }) => {
  const rootPath = `${__PATH_PREFIX__}/`;

  let headerTitle;
  if (location.path === rootPath) {
    headerTitle = (
      <h1>
        <img src={logo} alt="ロゴ" />
      </h1>
    );
  } else {
    headerTitle = (
      <h3>
        <img src={logo} alt="ロゴ" />
      </h3>
    );
  }

  return (
    <HeaderTag id="header">
      <News />
      <HeaderContainer>
        <Link to="/">{headerTitle}</Link>
        <Search />
      </HeaderContainer>
    </HeaderTag>
  );
};

export default Header;
