import React from "react";
import styled from "styled-components";

import iconTop from "../icon/top.svg";

const Wrapper = styled.div`
  bottom: 50px;
  cursor: pointer;
  opacity: 0;
  position: fixed;
  right: 15px;
  transform: translateY(-10px);
  transition: 0.3s;
  z-index: 99;
  &.shown{
    opacity: .7;
    transform: none;
  }
  @media (min-width: 769px) {
    right: calc((100% - 768px) / 2 - 60px);
  }
  img {
    height: 40px;
    width: 40px;
    @media (min-width: 769px) {
      height: 45px;
      width: 45px;
    }
  }
`;

const TopButton = () => {
  return (
    <Wrapper id="goTop">
      <img src={iconTop} alt="トップへ戻るボタン" />
    </Wrapper>
  );
};

export default TopButton;
