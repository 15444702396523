import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import iconPen2 from "../icon/pen2.svg";
import iconHourglass from "../icon/hourglass.svg";

import Image from "./Image";

const borderColor = (props) => props.theme.color.border;

const ArticleTag = styled.article`
  background: #fff;
  border: 1px solid ${borderColor};
  border-radius: 7px;
  display: grid;
  line-height: 1.5;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    box-shadow: 3px 8px 5px -5px hsl(0 0% 0% / 30%);
    transform: translateY(-3px);
  }
  img {
    display: block;
    max-width: 100%;
  }
  a:hover {
    text-decoration: none;
  }
`;

const ArticleTitleTag = styled.header`
  padding: 1em 1.5em;
  h2 {
    color: #333;
    font-size: 1.1em;
    margin-bottom: 0.3em;
    text-decoration: none;
    &:first-child {
      margin: 0 0 0.5em 0;
    }
  }
  span,
  time {
    font-size: 0.8em;
    margin-block-end: 0;
    margin-inline-end: 1.5em;
  }
  span:not(.reading) {
    border-radius: 3px;
    color: #fff;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    &.cat__dev {
      background: #444;
    }
    &.cat__gurumet {
      background: #ffb567;
    }
    &.cat__money {
      background: #4dba69;
    }
    &.cat__others {
      background: #0d54a0;
    }
  }
  time {
    color: #666;
    &::before {
      background: 0 0 / contain no-repeat url(${iconPen2});
      content: "";
      display: inline-block;
      height: 1em;
      margin-inline-end: 3px;
      vertical-align: middle;
      width: 1em;
    }
  }
  .reading {
    color: #666;
    &::before {
      background: 0 0 / contain no-repeat url(${iconHourglass});
      content: "";
      display: inline-block;
      height: 1em;
      margin-inline-end: 3px;
      vertical-align: middle;
      width: 1em;
    }
  }
`;

const PostLink = ({ post }) => {
  const data = post.frontmatter;

  return (
    <ArticleTag>
      <Link to={data.path} aria-label={data.title}>
        {!!data.thumbnail && <Image src={`eyeCatch/${data.thumbnail}`} />}
        <ArticleTitleTag>
          <h2>{data.title}</h2>
          <small>
            <span className={"cat__" + data.category}>{data.category}</span>
            <time>{data.date}</time>
            <span className="reading">{post.timeToRead}分</span>
          </small>
        </ArticleTitleTag>
      </Link>
    </ArticleTag>
  );
};
export default PostLink;
