import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const JsonLD = () => {
  return(
    <StaticQuery
      query={ jsonLDIndexQuery }
      render={
        data => {
          const { title, siteUrl, description, author } = data.site.siteMetadata;

          const publisher = {
            "@type": "Organization",
            name: author,
            logo: {
              "@type": "ImageObject",
              url: `${siteUrl}/assets/sai.png`,
              width: 150,
              height: 150
            }
          };

          const jsonLD = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            image: {
              "@type": "ImageObject",
              url: `${siteUrl}/assets/ogp.png`,
              height: 630,
              width: 1200
            },
            url: siteUrl,
            name: title,
            author: {
              "@type": "Person",
              name: author
            },
            description: description,
            publisher
          };

          return(
            <Helmet>
              <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
            </Helmet>
          );
        }
      }
    />
  );
};

export default JsonLD;

const jsonLDIndexQuery = graphql`
  query JsonLDIndexQuery{
    site{
      siteMetadata{
        title
        siteUrl
        description
        author
      }
    }
  }
`;