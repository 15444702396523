import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import "typeface-quicksand";

import Lang from "./Lang";

const Timeline = (props) => {
  const Wrap = styled.div`
    > * {
      transition: 0.5s;
    }
    h3 {
      color: rgba(0, 0, 0, 0.3);
      font-family: "Quicksand", sans-serif;
      font-size: 0.8em;
      margin-bottom: 1em;
    }
    time,
    p,
    ul {
      display: none;
    }
    ul {
      list-style: none;
    }
    &.active {
      margin-bottom: 40px;
      h3 {
        color: #333;
        font-size: 2em;
      }
      time,
      p,
      ul {
        animation: 1s fadeIn 0.3s forwards;
        display: block;
        opacity: 0;
        transform: translateY(50px);
      }
      time {
        font-size: 12px;
        text-align: right;
      }
      p {
        margin-bottom: 20px;
        a {
          color: initial;
          text-decoration: underline;
        }
      }
    }
    @keyframes fadeIn {
      100% {
        opacity: 1;
        transform: none;
      }
    }
  `;

  return (
    <Wrap id={`work${props.index}Timeline`} className="work__descrption">
      <h3>{props.name}</h3>
      <p>
        {props.description}
        {props.more && <Link to={props.more}>（もっと読む）</Link>}
      </p>
      <ul>
        {props.lang.map((l) => (
          <Lang lang={l} key={l} />
        ))}
      </ul>
      <time>{props.date}</time>
    </Wrap>
  );
};
export default Timeline;
