import React from "react";

// COMPONENT
import Switch from "../Switch";

// CSS
import styled from "styled-components";
import "typeface-quicksand";

const Wrap = styled.div`
  label {
    display: block;
    margin-bottom: 30px;
    span {
      display: block;
      font-family: "Quicksand", sans-serif;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      @media (min-width: 769px) {
        font-size: 28px;
      }
    }
    input[type="text"] {
      border: 2px solid #e6edf3;
      border-radius: 2px;
      font-size: 16px;
      padding: 0.5em;
      width: 100%;
    }
    input[type="color"] {
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      height: 32px;
      width: 32px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
    }
  }
`;

const SwitchWrap = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const CanvasController = (props) => {
  const handle = props.handle;
  const state = props.state;

  return (
    <Wrap>
      <label>
        <span>$ Command</span>
        <input
          type="text"
          placeholder="This is Sample Text"
          onChange={(e) => handle({ ...state, text: e.target.value })}
        />
      </label>
      <label>
        <span>Background Color</span>
        <input
          type="color"
          onChange={(e) =>
            handle({ ...state, backgroundColor: e.target.value })
          }
        />
      </label>

      <SwitchWrap>
        <Switch
          title="Darkmode"
          checked
          onChange={(e) => handle({ ...state, darkmode: e.target.checked })}
        />
        <Switch
          title="Terminal"
          checked
          onChange={(e) => handle({ ...state, terminal: e.target.checked })}
        />
      </SwitchWrap>
    </Wrap>
  );
};

export default CanvasController;
