import React, { useState } from "react";

import styled from "styled-components";

import Switch from "../Switch";
import SwitchBar from "./SwitchBar";

const Wrap = styled.ul`
  list-style: none;

  li {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
`;

const RadioWrap = styled.div`
  display: grid;
  gap: 10px;
`;

const Details = styled.details`
  summary {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    outline: none;
    padding-left: 20px;
    position: relative;
    transition: 0.3s;
    span {
      font-size: 12px;
    }
    &:hover {
      color: #e33d4f;
    }

    &::-webkit-details-marker {
      display: none;
    }
    &::after {
      border: 7px solid transparent;
      border-left: 8px solid #666;
      bottom: 0;
      content: "";
      height: 5px;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.3s;
      width: 5px;
    }
  }

  &[open] {
    summary {
      &::after {
        top: 6px;
        transform: rotate(90deg);
      }
    }
    .details-content {
      animation: fadeIn 1s;
      font-size: 16px;
      font-weight: normal;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
  }
`;

const Menu = (props) => {
  const price = ["無料", "有料"];
  const place = ["屋内", "屋外", "どこでも"];
  const time = ["10分", "1時間", "1日", "1ヶ月以上"];
  const purpose = [
    "笑いたい",
    "泣きたい",
    "楽しみたい",
    "学びたい",
    "眺めたい",
    "稼ぎたい",
    "頑張りたい",
  ];
  const age = ["10〜20代", "30〜50代", "60代以上", "全年齢"];

  return (
    <Wrap>
      <li>
        <Details>
          <summary>
            価格<span>で絞り込み</span>
          </summary>
          <div className="details-content">
            <RadioWrap>
              {price.map((e) => {
                return (
                  <SwitchBar
                    key={e}
                    title={e}
                    state={props.dataState}
                    onChange={() => {
                      props.dataHandleChange(e);
                    }}
                  />
                );
              })}
            </RadioWrap>
          </div>
        </Details>
      </li>
      <li>
        <Details>
          <summary>
            場所<span>で絞り込み</span>
          </summary>
          <div className="details-content">
            <RadioWrap>
              {place.map((e) => {
                return (
                  <SwitchBar
                    key={e}
                    title={e}
                    state={props.dataState}
                    onChange={() => {
                      props.dataHandleChange(e);
                    }}
                  />
                );
              })}
            </RadioWrap>
          </div>
        </Details>
      </li>
      <li>
        <Details>
          <summary>
            時間<span>で絞り込み</span>
          </summary>
          <div className="details-content">
            <RadioWrap>
              {time.map((e) => {
                return (
                  <SwitchBar
                    key={e}
                    title={e}
                    state={props.dataState}
                    onChange={() => {
                      props.dataHandleChange(e);
                    }}
                  />
                );
              })}
            </RadioWrap>
          </div>
        </Details>
      </li>
      <li>
        <Details>
          <summary>
            目的<span>で絞り込み</span>
          </summary>
          <div className="details-content">
            <RadioWrap>
              {purpose.map((e) => {
                return (
                  <SwitchBar
                    key={e}
                    title={e}
                    state={props.dataState}
                    onChange={() => {
                      props.dataHandleChange(e);
                    }}
                  />
                );
              })}
            </RadioWrap>
          </div>
        </Details>
      </li>
      <li>
        <Details>
          <summary>
            対象年齢<span>で絞り込み</span>
          </summary>
          <div className="details-content">
            <RadioWrap>
              {age.map((e) => {
                return (
                  <SwitchBar
                    key={e}
                    title={e}
                    state={props.dataState}
                    onChange={() => {
                      props.dataHandleChange(e);
                    }}
                  />
                );
              })}
            </RadioWrap>
          </div>
        </Details>
      </li>
    </Wrap>
  );
};

export default Menu;
