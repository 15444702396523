const variables = {
  media: {
    s: "480px",
    m: "980px",
    l: "1024px",
    xl: "1200px",
  },
  headerHeight: "82px",
  container: {
    news: "1200px",
    header: "1200px",
    category: "1200px",
    content: "1200px",
    footer: "1200px",
    main: "700px",
    side: "300px",
  },
  radius: {
    s: "3px",
    m: "7px",
    l: "10px",
    xl: "20px",
  },
  gap: {
    s: "15px",
    m: "30px",
    l: "50px",
    xl: "100px",
  },
  color: {
    border: "#e1e8ed",
  },
  font: {
    serif: "'Noto Serif JP', serif",
    deco: "'Abel', sans-serif",
    quickSand: '"Quicksand", sans-serif',
  },
};

export default variables;
