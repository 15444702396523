import React from "react";
import styled from "styled-components";

const contentWidth = (props) => props.theme.container.content;
const mainWidth = (props) => props.theme.container.main;
const sideWidth = (props) => props.theme.container.side;
const gapS = (props) => props.theme.gap.s;

const Container = styled.main`
  display: grid;
  gap: ${gapS};
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: ${gapS};
  width: 100%;

  @media (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${mainWidth}) {
    max-width: calc(${contentWidth} - ${sideWidth} - ${gapS} - ${gapS});
    min-width: ${mainWidth};
  }
`;

const PostsContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PostsContainer;
