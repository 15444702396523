import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENTS
import Seo from "../components/Seo";
import Layout from "../components/Layout";

// CSS
import styled from "styled-components";
import postContentStyle from "../styles/postContent";
import blockWrapperStyle from "../styles/blockWrapper";

const Wrap = styled.div`
  ${blockWrapperStyle}
  ${postContentStyle}
  .bold {
    font-size: 1.5em;
    font-weight: bold;
    display: block;
    margin: 0.5em 0 1em;
  }
  img {
    box-shadow: none !important;
  }
`;

const ThanksPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <Layout title={siteTitle} location={location}>
      <Seo pageTitle="Thank You 😸" location={location} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={`${siteUrl}/thanks`} />
      </Helmet>
      <Wrap>
        <div className="fuki">
          <div className="fuki__img">
            <img src="/assets/thanks.gif" alt="" />
          </div>
          <div className="fuki__txt">
            <p>
              <span className="bold">お問い合わせありがとうございます</span>
              <span>
                通常、2〜3日以内にご返信いたします。しばらくお待ちくださいませ。
              </span>
            </p>
          </div>
        </div>
      </Wrap>
    </Layout>
  );
};

export default ThanksPage;

export const pageQuery = graphql`
  query thanksPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
