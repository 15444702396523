import React, { useState, useEffect } from "react";
import axios from "axios";

import Seo from "../../components/Seo";
import Layout from "../../components/WhitehatSeo/Layout";
import Code from "../../components/WhitehatSeo/Code";

import styled from "styled-components";

import loadingIcon from "../../icon/loading.svg";

const gapS = (props) => props.theme.gap.s;
const gapM = (props) => props.theme.gap.m;
const mediaM = (props) => props.theme.media.m;
const radiusM = (props) => props.theme.radius.m;

const Input = styled.input`
  background: #fff;
  border-radius: ${radiusM};
  color: #ccc;
  font-size: 2em;
  margin-block-end: ${gapS};
  padding: ${gapS};
  width: 100%;
  @media (max-width: ${mediaM}) {
    font-size: 1.5em;
  }
`;

const LoadingWrap = styled.div`
  display: grid;
  height: 300px;
  margin: 100px auto;
  place-items: center;
  width: 300px;
  img {
    height: 50px;
    width: 50px;
  }
`;

const MovieInfoWrap = styled.div`
  align-items: center;
  background: #fff;
  border: 2px solid #2797f3;
  border-radius: 7px;
  display: grid;
  gap: ${gapS};
  grid-template-columns: 100px 1fr;
  padding: ${gapS} ${gapM};
  position: relative;

  &::after {
    background: #2797f3;
    color: #fff;
    content: "動画の情報";
    display: inline-block;
    font-size: 12px;
    left: 0;
    padding: 5px ${gapS};
    position: absolute;
    top: 0;
  }
`;

const Youtube = (props) => {
  // jsonデータ取得〜出力
  const [inputUrl, setInputUrl] = useState(
    "https://www.youtube.com/watch?v=LE-JN7_rxtE"
  );
  const [id, setId] = useState("LE-JN7_rxtE");
  const [search, setSearch] = useState(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${process.env.GATSBY_YOUTUBE_API_KEY}&fields=items(id,snippet(publishedAt,title,description,thumbnails(default)),contentDetails(duration),statistics(viewCount))&part=snippet,contentDetails,statistics`
  );
  const [response, setResponse] = useState({});
  const [jsonLd, setJsonLd] = useState({});

  // UI用
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // 初期値 reset
  const handleFocus = () => {
    setInputUrl("");
  };

  const handleChange = (e) => {
    setInputUrl(e.target.value);
    setIsError(false);
    setErrorMessage("");
    const inputUrl = e.target.value;
    // ユーザーの入力したURLごとにIDの取り出し方分岐
    // 自分が投稿した動画だと末尾にパラメータつくこともあるため末尾から抜き出す方法はNG
    if (inputUrl.indexOf("youtube.com") !== -1) {
      settingId(inputUrl, "?v=");
    } else if (inputUrl.indexOf("youtu.be") !== -1) {
      settingId(inputUrl, ".be/");
    } else {
      setIsError(true);
      setErrorMessage("何かがうまくいかなかったようです");
    }
  };

  const settingId = (str, target) => {
    const index = str.indexOf(target);
    const startPos = index + target.length;
    const cutId = str.substring(startPos, startPos + 11);
    setId(cutId);
  };

  // IDが取得できたらAPIにリクエストするURL生成
  useEffect(() => {
    setIsError(false);
    setErrorMessage("");
    if (id.length === 11) {
      setSearch(
        `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${process.env.GATSBY_YOUTUBE_API_KEY}&fields=items(id,snippet(publishedAt,title,description,thumbnails(default)),contentDetails(duration),statistics(viewCount))&part=snippet,contentDetails,statistics`
      );
    } else if (id.length > 0) {
      setTimeout(() => {
        setIsError(true);
        setErrorMessage("URLが間違っていませんか？");
      }, 3000);
    }
  }, [id]);

  // APIリクエスト処理
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(search).catch(() => {
        setIsError(true);
        setErrorMessage("何か間違っているようです");
      });
      if (!isError) {
        setResponse(result);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [search]);

  // 構造化データ作成
  useEffect(() => {
    if (Object.keys(response).length) {
      const d = response.data.items[0];
      setJsonLd({
        "@context": "http://schema.org",
        "@type": "VideoObject",
        name: d.snippet.title,
        description: d.snippet.description.replace(/\n/g, ""),
        thumbnailUrl: d.snippet.thumbnails.default.url,
        uploadDate: d.snippet.publishedAt,
        duration: d.contentDetails.duration,
        contentUrl: inputUrl,
        interactionCount: d.statistics.viewCount,
      });
    }
  }, [response]);

  return (
    <Layout id="video" kind="動画" common={false} {...props}>
      <Seo
        pageTitle="動画の構造化データ作成ツール"
        pageDescription="動画の構造化データを簡単に作成するツールです。構造化データをコード内に挿入することでSEO効果や検索結果画面でのクリック率の上昇が見込めます。"
        location={props.location}
      />

      <p style={{ marginBlockEnd: "15px" }}>
        構造化データを取得したいYouTubeのURLを
        <span className="emoji" role="img">
          👇
        </span>
        にペーストすると数秒で構造化データのコードができるよ
      </p>
      <Input
        type="text"
        value={inputUrl}
        plcaeholder="ここにURLをペーストしよう"
        onFocus={handleFocus}
        onChange={handleChange}
      />

      {isError ? errorMessage : ""}
      {isLoading ? (
        <LoadingWrap>
          <img src={loadingIcon} />
        </LoadingWrap>
      ) : !isError && Object.keys(response).length ? (
        <>
          <Code jsonLd={jsonLd} />
          <a href={inputUrl}>
            <MovieInfoWrap>
              <img src={jsonLd.thumbnailUrl} alt="" />
              <div>
                <p>{jsonLd.name}</p>
                <small style={{ color: "#999", wordBreak: "break-all" }}>
                  {jsonLd.description}
                </small>
              </div>
            </MovieInfoWrap>
          </a>
        </>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Youtube;
