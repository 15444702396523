import React from "react";
import { Link } from "gatsby";

import iconNew from "../icon/categoryIcon/new.svg";
// import iconFortuneTelling from "../icon/categoryIcon/tarot.svg";
import iconDev from "../icon/categoryIcon/dev.svg";
// import iconSaving from "../icon/categoryIcon/money.svg";
import iconWorks from "../icon/categoryIcon/pinata.svg";
import iconOthers from "../icon/categoryIcon/newspaper.svg";
// import iconGurumet from "../icon/categoryIcon/gurumet.svg";
import iconHima from "../icon/categoryIcon/gamepad.svg"
import iconGoogle from "../icon/categoryIcon/google.svg"
import iconContact from "../icon/categoryIcon/contact.svg";

import styled from "styled-components";

const container = (props) => props.theme.container.category;
const gapS = (props) => props.theme.gap.s;

const CategoryMenu = styled.nav`
  margin: 0 auto;
  max-width: ${container};
  padding: 80px ${gapS} ${gapS};
`;

const CategoryItemList = styled.ul`
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

const CategoryItem = styled.li`
  display: inline;
  &:not(:last-child) {
    margin-right: 1em;
  }
  a {
    color: #555;
    display: inline-block;
    padding-bottom: 5px;
    text-align: center;
    text-decoration: none;
    &:not([aria-current]) {
      header:hover {
        transform: translateY(-2px);
      }
    }
    header {
      padding-top: 5px;
      position: relative;
      transition: 0.2s;
      img {
        height: 35px;
        width: 35px;
        @media (min-width: 768px) {
          height: 40px;
          width: 40px;
        }
      }
    }
    small {
      display: block;
      margin-top: 10px;
    }
    &[aria-current] {
      font-weight: 700;
      border-bottom: 2px solid #e1e8ed;
      header::after {
        background: #e1e8ed;
        bottom: -3px;
        border-radius: 50%;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1;
        @media (min-widh: 768px) {
          bottom: -5px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    a header img {
      height: 50px;
      width: 50px;
    }
  }
`;

const CategoryLink = ({ categoryName, categoryIcon, categoryLink }) => {
  return (
    <CategoryItem>
      <Link to={categoryLink}>
        <header>
          <img src={categoryIcon} alt={categoryName} />
        </header>
        <small>{categoryName}</small>
      </Link>
    </CategoryItem>
  );
};

const Category = () => {
  return (
    <CategoryMenu id="categories">
      <CategoryItemList>
        <CategoryLink
          categoryName="新着"
          categoryIcon={iconNew}
          categoryLink="/"
        />
        {/* <CategoryLink
          categoryName="グルメ"
          categoryIcon={iconGurumet}
          categoryLink="/category/gurumet"
        /> */}
        <CategoryLink
          categoryName="開発"
          categoryIcon={iconDev}
          categoryLink="/category/dev"
        />
        {/* <CategoryLink
          categoryName="節約"
          categoryIcon={iconSaving}
          categoryLink="/category/saving"
        /> */}
        <CategoryLink
          categoryName="作ったモノ"
          categoryIcon={iconWorks}
          categoryLink="/portfolio"
        />
        <CategoryLink
          categoryName="暇つぶし名鑑"
          categoryIcon={iconHima}
          categoryLink="/himatsubushi"
        />
        <CategoryLink
          categoryName="SEOツール"
          categoryIcon={iconGoogle}
          categoryLink="/whitehat-seo-tools"
        />
        <CategoryLink
          categoryName="その他"
          categoryIcon={iconOthers}
          categoryLink="/category/others"
        />
        {/* <CategoryLink
          categoryName="占い"
          categoryIcon={iconFortuneTelling}
          categoryLink="/fortune-telling"
        /> */}
        <CategoryLink
          categoryName="運営者"
          categoryIcon={iconContact}
          categoryLink="/about"
        />
      </CategoryItemList>
    </CategoryMenu>
  );
};

export default Category;
