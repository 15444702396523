import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import styled from "styled-components";
import searchResultStyle from "../styles/searchResult";
import searchWrapperStyle from "../styles/searchWrapper";

import iconSe from "../icon/search.svg";
import iconIn from "../icon/information.svg";

const Wrapper = styled.div`
  ${searchWrapperStyle}
`;

const ResultWrapper = styled.div`
  ${searchResultStyle}
  li:last-child {
    display: flex;
    font-weight: bold;
    img {
      height: 20px;
      left: 0;
      padding-right: 0.5em;
      position: relative;
      vertical-align: text-top;
      width: 20px;
    }
  }
`;

// ハイライトマーカー
const TextHighlighter = (props) => {
  const { str, includes } = props;
  const temp = str.toLowerCase();
  const start = temp.indexOf(includes.toLowerCase());
  const end = start + includes.length;
  const item = str.slice(start, end);
  const res = str.replace(
    item,
    `<span style="background: rgba(255, 250, 118, .55); padding: 2px 0">${item}</span>`
  );
  return <span dangerouslySetInnerHTML={{ __html: res }} />;
};

const SearchResult = (props) => {
  const tempData = useStaticQuery(graphql`
    query SearchData {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY-MM-DD")
              title
              path
              tags
            }
          }
        }
      }
    }
  `);

  const [data, setDate] = useState([]);
  useEffect(() => {
    const temp = [];
    tempData.allMarkdownRemark.edges.map((e) => {
      temp.push(e.node.frontmatter);
      return "";
    });
    setDate(temp);
  }, []);

  // 検索結果表示非表示切り替え
  const [className, setClassName] = useState("");
  useEffect(() => {
    let id;
    if (props.focus && props.value !== "") {
      id = setTimeout(() => {
        setClassName("active");
      }, 100);
    } else {
      id = setTimeout(() => {
        setClassName("");
      }, 100);
    }
    return () => {
      clearTimeout(id);
    };
  }, [props.focus, props.value]);

  // 検索処理
  const [results, setResults] = useState([]);
  const search = () => {
    const value = props.value.toLowerCase();
    const temp = data.filter((e) => {
      const target = `
        ${e.title.toLowerCase()}
        ${e.tags.join(" ").toLowerCase()}
      `;
      return target.indexOf(value) !== -1;
    });
    setResults(temp);
  };
  useEffect(() => {
    if (props.value !== "") {
      search();
    }
  }, [props.value]);

  return (
    <ResultWrapper className={className}>
      <header>
        <b>{results.length}</b>件ヒットしました
      </header>
      <footer>
        <ul>
          {results.map((result) => {
            return (
              <li key={result.path}>
                <Link to={result.path}>
                  <TextHighlighter str={result.title} includes={props.value} />
                </Link>
              </li>
            );
          })}
          <li>
            <Link to="/search">
              <img src={iconIn} alt="お探しの記事が見つかりませんでしたか" />
              お探しの記事が見つかりませんか？
            </Link>
          </li>
        </ul>
      </footer>
    </ResultWrapper>
  );
}; // END searchResult

const Search = () => {
  const [focus, setFocus] = useState(false);
  const [value, setvalue] = useState("");
  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };
  const onChange = (e) => {
    setvalue(e.target.value);
  };

  return (
    <Wrapper focus={focus}>
      <label>
        <img src={iconSe} alt="検索アイコン" />
        <input
          type="text"
          placeholder="検索"
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
        />
      </label>
      <SearchResult focus={focus} value={value} />
    </Wrapper>
  );
};

export default Search;
