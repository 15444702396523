import React from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

// ICON
import iconSh from "../icon/share.svg";
import iconCb from "../icon/clipboard.svg";
import iconLi from "../icon/socialIcon/line.svg";
import iconTw from "../icon/socialIcon/twitter.svg";
import iconFb from "../icon/socialIcon/facebook.svg";

const mainWidth = props => props.theme.container.main
const contentWidth = props => props.theme.container.content
const gapS = props => props.theme.gap.s
const gapM = props => props.theme.gap.m
const mediaM = props => props.theme.media.m
const mediaL = props => props.theme.media.l
const mediaXl = props => props.theme.media.xl

const Wrapper = styled.div`
  opacity: 0;
  position: fixed;
  text-align: center;
  transform: translateY(-10px);
  transition: 0.3s;
  &.shown {
    opacity: 1;
    transform: none;
  }
  ul {
    list-style: none;
    img {
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
      }
    }
  }
  @media (max-width: ${mediaM}) {
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
    z-index: 3;
    ul {
      display: flex;
      justify-content: space-between;
      margin: auto;
      max-width: 480px;
      li {
        display: inline-block;
        &:first-child {
          display: none;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  @media (min-width: 981px) {
    left: calc((100vw - ${mainWidth}) / 2 - 100px);
    top: 20vh;
    ul {
      display: grid;
      grid-column: 1/3;
      grid-gap: 15px;
      grid-row: 1/2;
      width: 80px;
      img {
        height: 25px;
        width: 25px;
      }
    }
  }
  @media (min-width: ${mediaL}){
    left: 15px;
  }
  @media (min-width: ${mediaXl}){
    left: calc((100vw - ${contentWidth}) / 2 );
  }
`;

const ShareButtons = ({ path, title }) => {
  const pageUrl = `https://31navi.com${path}`;
  const handle = () => {
    alert("記事タイトルとURLをコピーしました");
  };

  return (
    <Wrapper id="sideShares">
      <ul>
        <li>
          <img src={iconSh} alt="SNSでシェアしよう" />
        </li>
        <li>
          <a
            href={`https://twitter.com/share?url=${pageUrl}&text=${title}&via=9631kunn`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img src={iconTw} alt="Twitterでシェア" title="Twitterでシェア" />
          </a>
        </li>
        <li>
          <a
            href={`https://www.facebook.com/share.php?u=${pageUrl}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img src={iconFb} alt="FaceBookでシェア" title="FaceBookでシェア" />
          </a>
        </li>
        <li>
          <a
            href={`https://timeline.line.me/social-plugin/share?url=${pageUrl}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <img src={iconLi} alt="Lineでシェア" title="lineでシェア" />
          </a>
        </li>
        <li>
          <CopyToClipboard text={title + " | " + pageUrl}>
            <img
              src={iconCb}
              alt="記事タイトルとURLをコピー"
              title="記事タイトルとURLをコピー"
              onClick={handle}
            />
          </CopyToClipboard>
        </li>
      </ul>
    </Wrapper>
  );
};

export default ShareButtons;
