import React from "react";

import styled from "styled-components";

const Lang = (props) => {
  const Lang = styled.li`
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    margin: 0 15px 15px 0;
    min-width: 90px;
    padding: 5px 0;
    text-align: center;
    &.html5 {
      background: #e44d26;
    }
    &.css3 {
      background: #3a98d1;
    }
    &.materialize {
      background: #e86b70;
    }
    &.js {
      background: #ceb32f;
    }
    &.php {
      background: #536290;
    }
    &.wordpress {
      background: #217296;
    }
    &.ruby,
    &.rails {
      background: #ab0f01;
    }
    &.heroku {
      background: #3e0195;
    }
    &.vue {
      background: #01bc7a;
    }
    &.react {
      background: #2a2c2e;
      color: #61dbfb;
    }
    &.gatsby {
      background: #522b81;
    }
    &.netlify {
      background: linear-gradient(-45deg, #11487d, #088591);
    }
    &.github {
      background: #313030;
    }
    &.blender {
      background: #ee9a34;
    }
    &.next {
      background: #000;
    }
    &.typescript {
      background: #3073bf;
    }
    &.illustrator {
      background: #f79604;
    }
  `;

  return <Lang className={props.lang.toLowerCase()}>{props.lang}</Lang>;
};

export default Lang;
