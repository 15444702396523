import React from "react";
import { ThemeProvider } from "styled-components";

import Header from "./Header";
import Category from "./Category";
import Aside from "./Aside";
import Footer from "./Footer";

import styled from "styled-components";
import theme from "../styles/themes/theme";
import GlobalStyle from "../styles/global";

const contentWidth = (props) => props.theme.container.content;
const mainWidth = (props) => props.theme.container.main;
const sideWidth = (props) => props.theme.container.side;
const gapS = (props) => props.theme.gap.s;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: ${contentWidth};
  padding: 0 ${gapS};
  width: 100%;
  @media (max-width: ${mainWidth}) {
    padding: 0;
  }
`;

const Main = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${mainWidth}) {
    max-width: calc(100% - ${sideWidth} - ${gapS} - ${gapS});
    min-width: ${mainWidth};
  }
`;

const Layout = (props) => {
  const { location, title, children } = props;

  return (
    <ThemeProvider theme={theme}>
      <Header title={title} location={location} />
      <Category />
      <Content>
        <Main>{children}</Main>
        <Aside {...props} />
      </Content>
      <Footer />
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default Layout;
