import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`

*,
*::before,
*::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* System Fonts as used by Medium and WordPress */
html{
  scroll-behavior: smooth;
}

body {
  color: #2f353d;
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Segoe UI,Hiragino Kaku Gothic ProN,Hiragino Sans,ヒラギノ角ゴ ProN W3,Arial,メイリオ,Meiryo,sans-serif;
  font-size: 16px;
}

a{
  color: #2296F3;
  text-decoration: none;
}

mark{
  background:linear-gradient(transparent 60%, #ff6 60%);
  color: inherit;
}

img{
  max-width: 100%;
}

blockquote{
  background: #f7f7f7;
  border: 1px solid #e1e8ed;
  border-radius: 5px;
  padding: 1em;
}

input{
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 0;
}

button{
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.emoji{
  margin: 0 .3em;
}

.post {
  > .blog-post-content {
    max-width: 768px;
    margin: 0 auto;
  }
  > .blog-post-content *:last-child,
  > *:last-child {
    margin-bottom: 0;
  }
  .post-title {
    text-align: center;
    margin: 0 0 0.5rem;
    line-height: 1.3;
    font-size: 2rem;
  }
  .post-meta {
    margin-bottom: 32px;
    text-align: center;
  }
}

.none{
  display: none;
}
@media(max-width: 768px){
  .sp__none{
    display: none;
  }
}
@media(min-width: 769px){
  .pc__none{
    display: none;
  }
}

.transparent{
  opacity: 0;
}

.relative{
  position: relative;
}

`;

export default globalStyle;
