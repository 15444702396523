import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// COMPONENTS
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Tags from "../components/Tags";
import Search from "../components/Search";

// CSS
import blockWrapperStyle from "../styles/blockWrapper";

const Wrapper = styled.div`
  ${blockWrapperStyle}
  h1 {
    font-size: 20px;
    margin: 1em 0;
    text-align: left;
  }
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const Block = styled.div`
  margin-bottom: 20px;
  & > * {
    padding: 10px;
  }
  &.search {
    img {
      left: 20px;
    }
    input[type="text"] {
      max-width: 768px;
      width: 100%;
    }
  }
  &.category {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      li {
        border: 1px solid #e1e8ed;
        border-radius: 7px;
        text-align: center;
        @media (max-width: 480px) {
          margin-bottom: 20px;
          width: calc(50% - 10px);
        }
        @media (min-width: 481px) {
          width: calc(25% - 20px);
        }
        a {
          color: inherit;
          display: block;
          padding: 0.5em;
        }
      }
    }
  }
`;

class SearchPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const siteUrl = data.site.siteMetadata.siteUrl;
    const tags = data.allMarkdownRemark.group;
    let tagsList = [];

    tags.map((tag) => {
      tagsList.push(tag.tag);
      return "";
    });

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Wrapper>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <SEO pageTitle="検索ページ" location={this.props.location} />
          <h1>お探しの記事が見つかりませんか？</h1>
          <Block className="search">
            <h2>
              <span role="img" aria-label="探すアイコン">
                🔍
              </span>{" "}
              検索から探す
            </h2>
            <Search />
          </Block>
          <Block className="category">
            <h2>
              <span role="img" aria-label="探すアイコン">
                📂
              </span>{" "}
              カテゴリから探す
            </h2>
            <ul>
              <li>
                <a href="/category/gurumet">グルメ</a>
              </li>
              <li>
                <a href="/category/dev">開発</a>
              </li>
              <li>
                <a href="/category/money">節約</a>
              </li>
              <li>
                <a href="category/others">その他</a>
              </li>
            </ul>
          </Block>
          <Block className="tags">
            <h2>
              <span role="img" aria-label="探すアイコン">
                🏷
              </span>{" "}
              タグから探す
            </h2>
            <Tags tags={tagsList} />
          </Block>
        </Wrapper>
      </Layout>
    );
  }
}

export default SearchPage;

export const pageQuery = graphql`
  query searchPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
      }
    }
  }
`;
