import React, { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";

const Test = (data) => {
  const items = data.allHimatsubushi.nodes;

  // LIKE数取得し、Stateに保管
  const [likes, setLikes] = useState(null);
  useEffect(() => {
    firebase
      .firestore()
      .collection("hima_likes")
      .get()
      .then((docs) => {
        const array = [];
        docs.forEach((doc) => {
          array.push({
            id: doc.data().id,
            like: doc.data().like,
          });
          setLikes(array);
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }, []);

  //LIKE Count up
  const likeCountUp = async (id) => {
    await firebase
      .firestore()
      .collection("hima_likes")
      .doc(id)
      .get()
      .then((doc) => {
        console.log(doc.data().like);
      });
  };

  return (
    <div style={{ paddingTop: "100px" }}>
      {items.map((i) => {
        return (
          <p key={i.id}>
            {i.id} - {i.title}
            <button
              onClick={() => {
                likeCountUp(i.id);
              }}
            >
              Like
            </button>
          </p>
        );
      })}
    </div>
  );
};

export default Test;
