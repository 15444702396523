import React, { useState } from "react";

import styled from "styled-components";

import Sidebar from "./Sidebar";
import Content from "./Content";

const contentWidth = props => props.theme.container.content
const gapS = props => props.theme.gap.s

const Wrap = styled.div`
  display: flex;
  max-width: ${contentWidth};
  margin: 0 auto;
  @media (min-width: 790px) {
    padding: 0 ${gapS};
  }
`;

const MainArea = () => {
  // 絞り込み条件一覧
  const [condition, setCondition] = useState([]);

  // 絞り込み条件更新
  const handleChange = (newItem) => {
    let currentState = [];
    currentState.push(...condition);
    const index = currentState.indexOf(newItem);
    index !== -1 ? currentState.splice(index, 1) : currentState.push(newItem);
    setCondition(currentState);
  };

  return (
    <Wrap>
      <Sidebar
        dataState={condition}
        dataHandleChange={(e) => {
          handleChange(e);
        }}
      />
      <Content
        dataState={condition}
        dataHandleChange={(e) => {
          handleChange(e);
        }}
      />
    </Wrap>
  );
};

export default MainArea;
