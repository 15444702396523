import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// COMPONENT
import Seo from "../components/Seo";
import Header from "../components/Header";
import Category from "../components/Category";
import CanvasArea from "../components/Terminal-Image/CanvasArea";
import Adsense from "../components/Adsense";
import Footer from "../components/Footer";

// CSS
import GlobalStyle from "../styles/global";
import blockWrapperStyle from "../styles/blockWrapper";
import styled, { ThemeProvider } from "styled-components";
import theme from "../styles/themes/theme";

const PcWrap = styled.div`
  ${blockWrapperStyle}
  margin-bottom: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SpWrap = styled.div`
  ${blockWrapperStyle}
  margin-bottom: 30px;

  p {
    text-align: center;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const AdsenseWrap = styled.div`
  ${blockWrapperStyle}
`;

const TerminalImage = ({ data, location }) => {
  const siteTitle = "ターミナル画像ジェネレーター";
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Seo
        pageTitle={siteTitle}
        pageDescription="ターミナルの上でコマンドを実行しているイメージ画像を生成するツールです"
        thumbnail="terminal_image_generator.png"
        location={location}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}/terminal-image`} />
      </Helmet>
      <Header location={location} />
      <Category />
      <AdsenseWrap>
        <Adsense slot="2830199047" format="link" path={location.pathname} />
      </AdsenseWrap>
      <PcWrap>
        <CanvasArea siteTitle={siteTitle} path={location.pathname} />
      </PcWrap>
      <SpWrap>
        <p>Sorry, This pages doesn't work on smart phone</p>
      </SpWrap>
      <AdsenseWrap>
        <Adsense path={location.pathname} />
      </AdsenseWrap>
      <Footer />
    </ThemeProvider>
  );
};

export default TerminalImage;

export const portfolioQuery = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`;
