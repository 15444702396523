const variables = {
  media: {
    s: "480px",
    m: "980px",
    l: "1024px",
    xl: "1200px",
  },
  container: {
    content: "1024px",
    main: "760px",
  },
  radius: {
    s: "3px",
    m: "7px",
    l: "10px",
    xl: "20px",
  },
  gap: {
    s: "15px",
    m: "30px",
    l: "50px",
    xl: "100px",
  },
  color: {
    border: "#e1e8ed",
  },
  font: {
    serif: "'Noto Serif JP', serif",
    deco: "'Abel', sans-serif",
  },
};

export default variables;
