import React, { useState } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import useWindowSize from "react-use/lib/useWindowSize";
import { Link } from "gatsby";

// COMPONENTS
import Seo from "../components/Seo";
import Confetti from "react-confetti";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

// CSS
import styled, { ThemeProvider } from "styled-components";
import theme from "../styles/themes/theme";
import GlobalStyle from "../styles/global";
import "typeface-press-start-2p";
import blockWrapperStyle from "../styles/blockWrapper";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

// Image
import htmlIcon from "../icon/html.svg";
import cssIcon from "../icon/css.svg";
import jsIcon from "../icon/js.svg";

const QuizPage = ({ data, location }) => {
  // Meta
  // const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const questionsData = data.allQuestion.nodes;

  // Categories
  const [html, setHtml] = useState(false);
  const [css, setCss] = useState(false);
  const [js, setJs] = useState(false);

  // Level
  const [qlevel, setQlevel] = useState(10);

  // Animation
  const [animHook, setAnimHook] = useState(false);
  const [start, setStart] = useState(false);
  const [point, setPoint] = useState(0);

  // Questions(show/hidden)
  const [currentQuestion, setCurrentQuestion] = useState([true, false, false]);
  const [showQuestions, setShowQuestions] = useState(questionsData);

  // WindowSize to use Confetti
  const { width, height } = useWindowSize();
  const [correct, setCorrect] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [correctAnime, setCorrectAnime] = useState(false);
  const [incorrectAnime, setIncorrectAnime] = useState(999); // 初期値はaIndex(0~3)と被らない数値にする

  // Lines
  const lines = [
    ["hi", "what's up", "howdy", "(-_-)", "come on"],
    ["You Win", "Damn it!!", "Screw it!", "That sucks!", "(T_T)"],
    ["You Loser", "Ez", "(^-^)", "(^0^)", "got ya!!"],
  ];

  // Styles //

  const Wrap = styled.div`
    background: #111;
    height: ${height ? height + "px" : "100vh"};
    position: relative;
    @media (min-width: 769px) {
      padding-top: 20px;
    }
  `;

  const PostContent = styled.div`
    ${blockWrapperStyle}
    background: #111;
    height: 100%;
    @media (max-width: 480px) {
      padding: 10px;
    }
    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
    @media (min-width: 769px) {
      padding-bottom: 200px;
    }
    & > * {
      color: #fff;
    }
    h1 {
      text-align: center;
      margin-bottom: 50px;
    }
    .fadeOut {
      animation: fadeOut 1s forwards;
    }
    .fadeOut2 {
      animation: fadeOut2 1.9s forwards;
    }
    .fadeIn {
      animation: 1s fadeIn forwards;
    }
    .none {
      display: none;
    }
    .home {
      border: 1px solid;
      border-radius: 50%;
      bottom: 10px;
      left: 10px;
      line-height: 30px;
      position: absolute;
      text-align: center;
      width: 30px;
    }
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fadeOut2 {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }
      100% {
        opacity: 1;
      }
    }
  `;

  const ButtonArea = styled.div`
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 481px) {
      grid-template-columns: 1fr 1fr;
    }
  `;

  const Button = styled.div`
    cursor: pointer;
    margin: 0 auto;
    width: 100%;
    p {
      background: #111;
      border: 3px solid #fff;
      border-radius: 10px;
      color: #fff;
      line-height: 25px;
      padding: 5px;
      text-align: center;
      img {
        height: 25px;
        vertical-align: middle;
        width: 25px;
      }
    }
    &.center p {
      text-align: center;
    }
    &.able p {
      border: 3px solid #007bff;
      border-radius: 10px;
    }
    &.disable p {
      border: 3px solid #dc3545;
      border-radius: 10px;
      cursor: not-allowed;
      opacity: 0.25;
    }
    &.html p {
      border-color: #fe896b;
    }
    &.css p {
      border-color: #2296f3;
    }
    &.js p {
      border-color: #efdb4f;
    }
    &.correct p {
      border-color: #28a745;
    }
    &.incorrect p {
      border-color: #dc3545;
    }
    &.again {
      max-width: 768px;
      p {
        background: #333;
        border-radius: 4px;
        color: #fff;
      }
    }
  `;

  const StartView = styled.div`
    align-items: center;
    display: grid;
    grid-template-rows: 100px 1fr 2fr;
    height: 100%;
  `;

  const QuestionView = styled.div`
    height: 100%;
  `;

  const QuizWrap = styled.div`
    height: 100%;
    &.show {
      align-items: center;
      display: grid;
      grid-gap: 10px;
      grid-template-rows: 60px 2fr 1fr;
    }
    li {
      background: #333;
    }
  `;

  const QuestionDetail = styled.div`
    img {
      border-radius: 3px;
      display: block;
      height: 80px;
      margin: 10px auto 0;
    }
    .code__lang {
      background: #222;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      font-size: 12px;
      padding: 5px;
    }
    pre {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      margin: 0 !important;
      overflow-y: scroll !important;
      padding: 10px 15px;
      white-space: break-spaces !important;
      word-break: break-all !important;
      @media (min-width: 481px) {
        height: 150px;
      }
      @media (max-width: 480px) {
        height: 120px;
      }
      code {
        font-size: 12px;
        white-space: break-spaces !important;
        word-break: break-all !important;
      }
    }
  `;

  const Browser = styled.div`
    margin: 10px auto 0;
    .browser__bar {
      background: #2d2d2d;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      display: block;
      height: 18px;
      position: relative;
      span,
      span::before,
      span::after {
        border-radius: 50%;
        display: inline-block;
        height: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
      }
      span {
        background: #ff7676;
        left: 10px;
        &::before {
          background: #ffc41f;
          content: "";
          left: 10px;
        }
        &::after {
          background: #46e26b;
          content: "";
          left: 20px;
        }
      }
    }
    iframe {
      border: 3px solid #2d2d2d;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      background: #fff;
      height: 80px;
      overflow-y: scroll;
      width: 100%;
    }
  `;

  const Console = styled.div`
    margin-top: 10px;
    position: relative;
    .con__ttl {
      background: #222;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      display: block;
      font-size: 12px;
      left: 0;
      padding: 5px;
      position: absolute;
      top: 0;
      width: 100%;
    }
    p {
      background: #2d2d2d;
      border-radius: 3px;
      color: #fff;
      font-family: monospace;
      font-size: 12px;
      height: 80px;
      margin: 0.5em 0;
      overflow-y: scroll;
      padding: 40px 1em 1em;
      white-space: break-spaces;
      word-break: break-word;
    }
  `;

  const Result = styled.div``;

  const Enemy = styled.div`
    align-items: center;
    background: #111;
    border: 3px solid #fff;
    border-radius: 10px;
    color: #fff;
    display: grid;
    font-family: "Press Start 2P", cursive;
    font-size: 12px;
    grid-gap: 15px;
    grid-template-columns: 80px 1fr;
    padding: 5px;
    position: relative;
    .emoji {
      font-size: 16px;
    }
    .enemyImg {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1fr;
      span {
        text-align: center;
      }
    }
  `;

  // Functions //

  // 選択レベルをhookへ
  const chooseLevel = () => {
    if (qlevel === 10) {
      setQlevel(50);
    } else if (qlevel === 50) {
      setQlevel(100);
    } else if (qlevel === 100) {
      setQlevel(10);
    }
  };

  // アニメ関係hookの有効化
  const startAnim = () => {
    if (html || css || js) {
      setAnimHook(true);
      setTimeout(() => {
        setStart(true);
      }, 1200);
    }
  };

  // 選択レベルhookから問題抽出
  const pickUpQuestion = () => {
    // 配列をシャッフル処理
    const shuffle = ([...array]) => {
      for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // GraphQLから持ってきたデータをシャッフル後、ユーザーの選択状況に合わせ表示する問題を絞り込む
    const newQuestions = shuffle(showQuestions).filter((q) => {
      // カテゴリによる絞り込み
      if (
        (html && q.cat === "html") ||
        (css && q.cat === "css") ||
        (js && q.cat === "js")
      ) {
        // 難易度による絞り込み
        if (
          (qlevel === 10 && q.level <= 10) ||
          (qlevel === 50 && q.level <= 50) ||
          (qlevel === 100 && q.level >= 50 && q.level <= 100)
        ) {
          return q;
        }
      }
      return q;
    });
    setShowQuestions(newQuestions);
  };

  // 正誤判定
  const check = (correct, answer, level, current) => {
    // アニメスタート
    setCorrectAnime(true);

    // 合ってたらポイント加算と紙吹雪 間違ってたら間違いアニメ
    if (correct === answer) {
      setCorrect(true);
      setPoint(point + level * 50);
    } else {
      setIncorrectAnime(answer);
      setIncorrect(true);
    }

    setTimeout(() => {
      // アニメ終了
      setCorrectAnime(false);

      // Incorrect終了
      setIncorrect(false);
      setIncorrectAnime(999);

      // Confetti終了
      setCorrect(false);

      // 配列をシャローコピーしてまるごと書き換え
      const newQuestion = currentQuestion.slice();
      newQuestion[current] = false;
      newQuestion[current + 1] = true;
      setCurrentQuestion(newQuestion);
    }, 2000);
  };

  const reset = () => {
    setHtml(false);
    setCss(false);
    setJs(false);
    setAnimHook(false);
    setStart(false);
    setQlevel(10);
    setPoint(0);
    setCurrentQuestion([true, false, false]);
    setShowQuestions(questionsData);
    setCorrect(false);
    setIncorrect(false);
    setCorrectAnime(false);
    setIncorrectAnime(999);
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrap location={location}>
        <GlobalStyle />
        <Seo
          pageTitle="HTML/CSS/JSの確認テスト"
          pageDescription="目指せ！フロントエンドマスター！"
          location={location}
        />
        <Helmet>
          <link rel="canonical" href={`${siteUrl}/exam`} />
        </Helmet>
        <PostContent>
          {!start ? (
            <>
              <StartView className={animHook ? "fadeOut" : ""}>
                <h1>
                  <span
                    role="img"
                    aria-label="HTML/CSS/JS初心者向けテストイメージ"
                  >
                    👨‍💻👩‍💻 💥💥 🥚🐣🐓
                  </span>
                </h1>
                <ButtonArea>
                  <Button
                    className={html ? "active html" : ""}
                    onClick={() => {
                      setHtml(!html);
                    }}
                  >
                    <p>
                      <img src={htmlIcon} alt="HTML logo" />
                    </p>
                  </Button>
                  <Button
                    htmlFor="css"
                    className={css ? "active css" : ""}
                    onClick={() => {
                      setCss(!css);
                    }}
                  >
                    <p>
                      <img src={cssIcon} alt="css logo" />
                    </p>
                  </Button>
                  <Button
                    htmlFor="js"
                    className={js ? "active js" : ""}
                    onClick={() => {
                      setJs(!js);
                    }}
                  >
                    <p>
                      <img src={jsIcon} alt="js logo" />
                    </p>
                  </Button>
                  <Button
                    htmlFor="level"
                    className="center"
                    onClick={() => {
                      chooseLevel();
                    }}
                  >
                    <p>
                      <span role="img" aria-label="難易度選択">
                        👨‍💻👩‍💻 💥💥{" "}
                        {qlevel === 10
                          ? "🥚"
                          : qlevel === 50
                          ? "🐣"
                          : qlevel === 100
                          ? "🐓"
                          : ""}
                      </span>
                    </p>
                  </Button>
                </ButtonArea>
                <Button
                  className={html || css || js ? "able start" : "disable start"}
                  onClick={() => {
                    startAnim();
                    pickUpQuestion();
                  }}
                >
                  <p>{html || css || js ? "🏃‍♂️" : "🚶‍♂️"}</p>
                </Button>
              </StartView>
              <Link to="/" className="home">
                ←
              </Link>
            </>
          ) : (
            <QuestionView>
              {showQuestions.map((q, qIndex) => {
                if (qIndex <= 2) {
                  return (
                    <QuizWrap
                      key={q.id}
                      //一番最初の質問のみ表示、次からの要素は順次表示
                      className={currentQuestion[qIndex] ? "show" : "none"}
                    >
                      <Enemy>
                        <div className="enemyImg">
                          <span
                            className="emoji"
                            role="img"
                            aria-label="敵キャラクター"
                          >
                            {q.level === 10
                              ? "🥚"
                              : q.level === 50
                              ? "🐣"
                              : "🐓"}
                          </span>
                          <span>Lv.{q.level}</span>
                        </div>
                        <p>
                          {correct
                            ? ` ${lines[1][qIndex]}`
                            : incorrect
                            ? ` ${lines[2][qIndex]} `
                            : ` ${lines[0][qIndex]} `}{" "}
                        </p>
                      </Enemy>
                      <QuestionDetail>
                        <p className="code__lang">{q.cat.toUpperCase()}</p>
                        <SyntaxHighlighter language={q.cat} style={tomorrow}>
                          {q.q}
                        </SyntaxHighlighter>
                        {!!q.img && <img src={q.img} alt="図" />}
                        {!!q.fig && (
                          <Browser>
                            <div className="browser__bar">
                              <span></span>
                            </div>
                            <iframe
                              srcDoc={q.fig}
                              title={qIndex + 1 + "問目の図"}
                            />
                          </Browser>
                        )}
                        {!!q.con && (
                          <Console>
                            <div className="con__ttl">>_ CONSOLE</div>
                            <p>{q.con}</p>
                          </Console>
                        )}
                      </QuestionDetail>
                      <ButtonArea>
                        {q.a.map((a, aIndex) => {
                          return (
                            <Button
                              key={aIndex}
                              className={
                                correctAnime && q.correct === aIndex
                                  ? "correct"
                                  : incorrectAnime === aIndex
                                  ? "incorrect"
                                  : ""
                              }
                              onClick={() => {
                                check(q.correct, aIndex, q.level, qIndex);
                              }}
                            >
                              <p>{a}</p>
                            </Button>
                          );
                        })}
                      </ButtonArea>
                      <Confetti
                        width={width}
                        height={height}
                        numberOfPieces={300}
                        gravity={0.4}
                        initialVelocityY={20}
                        className={correct ? "fadeOut2" : "none"}
                      />
                    </QuizWrap>
                  );
                }
                return "";
              })}
              <Result className={currentQuestion[3] ? "fadeIn" : "none"}>
                <p>You Get {point} FE points</p>
                <Button onClick={reset} className="again">
                  <p>Try Again</p>
                </Button>
              </Result>
            </QuestionView>
          )}
        </PostContent>
      </Wrap>
    </ThemeProvider>
  );
};

export default QuizPage;

export const pageQuery = graphql`
  query quizPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allQuestion {
      nodes {
        id
        q
        a
        correct
        level
        img
        fig
        con
        cat
      }
    }
  }
`;
