import React from "react";
import { Link } from "gatsby";

// CSS
import styled from "styled-components";

// COMPONENTS
import Lang from "./Lang";
import Image from "../Image";

const Work = (props) => {
  const Wrap = styled.div`
    margin-bottom: 100px;
    @media (min-width: 769px) {
      &:not(:last-of-type) {
        margin-bottom: 600px;
      }
    }
    @media (min-width: 769px) {
      img {
        box-shadow: 0 25px 20px -20px hsl(200 50% 20% / 40%);
      }
    }
  `;

  const Caption = styled.div`
    margin: auto;
    width: 95%;
    h3 {
      color: #333;
      font-family: "Quicksand", sans-serif;
      font-size: 1.7em;
      margin: 0.5em 0;
      text-align: center;
    }
    ul {
      list-style: none;
    }
    time {
      display: block;
      font-size: 12px;
      text-align: right;
    }
    p {
      margin-bottom: 20px;
      a {
        color: initial;
        text-decoration: underline;
      }
    }
  `;

  const Button = styled.div`
    background: #333;
    border-radius: 30px;
    box-shadow: 0 20px 20px -10px hsl(200 50% 20% / 40%);
    color: #fff;
    font-size: 1.2em;
    margin: 60px auto 0;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    width: 150px;
  `;

  return (
    <Wrap>
      <picture id={`work${props.index}`} className="work__image">
        <Image src={`portfolio/work${props.index}.png`} />
      </picture>
      <Caption className="pc__none">
        <h3>{props.name}</h3>
        <p>
          {props.description}
          {props.more && <Link to={props.more}>（もっと読む）</Link>}
        </p>
        <ul>
          {props.lang.map((l) => (
            <Lang lang={l} key={l} />
          ))}
        </ul>
        <time>{props.date}</time>
      </Caption>
      {props.url && (
        <a href={props.url}>
          <Button>Go To!</Button>
        </a>
      )}
    </Wrap>
  );
};

export default Work;
