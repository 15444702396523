import React from "react";

import styled from "styled-components";

import ContentBackGround from "./ContentBackGround";
import ContentLists from "./ContentLists";
import SpMenu from "./SpMenu";

const Wrap = styled.div`
  margin: auto;
  max-width: 760px;
  width: 100%;
  @media (min-width: 768px) {
    border-left: 1px solid #e1e8ed;
    border-right: 1px solid #e1e8ed;
  }
`;

const Content = (props) => {
  return (
    <Wrap>
      <ContentBackGround />
      <ContentLists {...props} />
      <SpMenu {...props} />
    </Wrap>
  );
};

export default Content;
