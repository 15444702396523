import React, { useState, useEffect } from "react";

import Seo from "../../components/Seo";
import Layout from "../../components/WhitehatSeo/Layout";
import Code from "../../components/WhitehatSeo/Code";

import styled from "styled-components";

import trashIcon from "../../icon/whitehatseotools/trash.svg";

const gapS = (props) => props.theme.gap.s;

const InputWrap = styled.div`
  margin-block-end: ${gapS};
  padding-block-end: calc(
    (14px + 10px + 10px) + ${gapS} + 10px
  ); /* ボタン幅 + gap + ちょい余白 */
  padding-inline-end: 30px; /* 削除ボタン */
  position: relative;
  &::before,
  &::after {
    background: #fff;
    bottom: calc(${gapS} + 5px); /* gap + ちょい余白/2 */
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    opacity: 0.5;
    padding: 10px 15px;
    position: absolute;
    width: calc((50% - ${gapS} / 2) - 15px);
  }
  &::before {
    content: "質問";
    left: 0;
  }
  &::after {
    content: "答え";
    right: 30px;
  }
`;

const FaqWrap = styled.div`
  display: grid;
  gap: ${gapS};
  grid-template-columns: 1fr 1fr;
`;

const ButtonWrap = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #e4eef5 60%
  ); /* safariのtransparentグラデ対策 */
  bottom: calc(${gapS} - 5px); /* gap - ちょい余白/2 */
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
`;

const Button = styled.button`
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 15px -5px hsl(0 0% 0% / 30%);
  color: #111;
  font-size: 16px;
  line-height: 1;
  padding: 10px 30px;
  transition: 0.5s;
  &:hover {
    color: #2296f3;
  }
`;

const Input = styled.input`
  background: #fff;
  font-size: 16px;
  margin-block-end: ${gapS};
  line-height: 1;
  padding: 10px 15px;
  width: 100%;
`;

const Trash = styled.div`
  line-height: 38px;
  position: absolute;
  right: 0;
  &::before {
    background: url(${trashIcon});
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 20px;
    transition: 0.5s;
    width: 20px;
  }
`;

const Error = styled.div`
  background: #ffeff0;
  border-left: 4px solid #f7605f;
  border-radius: 1px 7px 7px 1px;
  margin-block-end: ${gapS};
  padding: 0.75em 0.9em;
`;

const Faq = (props) => {
  // jsonデータ取得〜出力
  const [jsonLd, setJsonLd] = useState({});
  const [faq, setFaq] = useState([{ name: "", answer: "" }]);
  const [count, setCount] = useState(0);

  // ui用
  const [error, setError] = useState("");

  const handleFirstChange = (e) => {
    setFaq([{ ...faq[0], [e.target.name]: e.target.value }]);
  };

  const handleChange = (e, i) => {
    setError("");
    const update = () => {
      if (e.target.name === "name") {
        faq[i].name = e.target.value;
      } else {
        faq[i].answer = e.target.value;
      }
      return [...faq];
    };
    setFaq(update());
  };

  const handleDelete = (i) => {
    const newVal = [...faq];
    newVal.splice(i, 1);
    setFaq(newVal);
  };

  const incremental = () => {
    if (faq[faq.length - 1].name === "" || faq[faq.length - 1].answer === "") {
      setError(
        "ちゃんと空白を全部入力してから新しくFAQを追加しよう！そういうとこだぞ！"
      );
      return "";
    }
    setFaq([...faq, { name: "", answer: "" }]);
    setCount(count + 1);
  };

  // 構造化データ作成
  useEffect(() => {
    // 入力値を構造化データ用フォーマットに
    const jsonData = faq.map((f) => ({
      "@type": "Question",
      name: f.name,
      acceptedAnswer: {
        "@type": "Answer",
        text: f.answer,
      },
    }));
    // 構造化データ生成
    setJsonLd({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [...jsonData],
    });
  }, [faq]);

  return (
    <Layout id="faq" kind="よくある質問" common={false} {...props}>
      <Seo
        pageTitle="求人の構造化データ作成ツール"
        pageDescription="求人の構造化データを簡単に作成するツールです。構造化データをコード内に挿入することでSEO効果や検索結果画面でのクリック率の上昇が見込めます。"
        location={props.location}
      />

      <p style={{ marginBlockEnd: "15px" }}>
        <span className="emoji" role="img">
          👇
        </span>
        下の入力欄を埋めていくと構造化データが生成されるよ。HTMLもOKだよ:)
      </p>
      <InputWrap>
        {faq.map((obj, i) => (
          <FaqWrap key={"faq" + i.toString()}>
            <Input
              placeholder="質問"
              name="name"
              type="text"
              onChange={(e) =>
                i === 0 ? handleFirstChange(e) : handleChange(e, i)
              }
              value={obj.name}
            />
            <Input
              placeholder="答え"
              name="answer"
              type="text"
              onChange={(e) =>
                i === 0 ? handleFirstChange(e) : handleChange(e, i)
              }
              value={obj.answer}
            />
            {i >= 1 && (
              <Trash
                onClick={() => {
                  handleDelete(i);
                }}
              />
            )}
          </FaqWrap>
        ))}
        <ButtonWrap>
          <Button onClick={incremental}>FAQを追加する</Button>
        </ButtonWrap>
      </InputWrap>
      {error ? <Error>{error}</Error> : ""}
      <Code jsonLd={jsonLd} />
    </Layout>
  );
};

export default Faq;
