import { css } from 'styled-components';

const searchResultStyle = css`
  background: #fff;
  border: 2px solid #e1e8ed;
  border-radius: 3px;
  box-shadow: 1px 1px 20px rgba(0,0,0,.39);
  font-size: .9em;
  max-width: 400px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 45px;
  transform: translateY(-20px);
  transition: .3s;
  visibility: hidden;
  width: 90vw;
  z-index: 99999;
  &.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  header, footer{
    padding: 10px;
  }
  header{
    border-bottom: 2px solid #e1e8ed;
    letter-spacing: .5;
    b{
      margin-right:  .25em;
    }
  }
  footer{
    ul{
      list-style: none;
      max-height: 210px;
      overflow-y: scroll;
      li{
        padding: .5em 0;
        &:not(:last-child){
          border-bottom: 1px dashed #e1e8ed;
        }
        a{
          color: #333;
          display: block;
        }
      }
    }
  }
`;

export default searchResultStyle;
