import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

// COMPONENTS
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Tags from "../components/Tags";
import Search from "../components/Search";

// CSS
import blockWrapperStyle from "../styles/blockWrapper";
import glitch from "../styles/animation/glitch";
import glitchTop from "../styles/animation/glitchTop";
import glitchBottom from "../styles/animation/glitchBottom";

const mediaM = (props) => props.theme.media.m;

const Wrapper = styled.div`
  ${blockWrapperStyle}
  h1 {
    font-size: 20px;
    margin: 2em 0;
    text-align: center;
  }
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const NotFoundImage = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");
  ${blockWrapperStyle}
  align-items: center;
  background-color: #111;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #333;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 150px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  strong {
    animation: ${glitch} 1s linear infinite;
    font-family: "Fira Mono", monospace;
    font-size: 50px;
    &::before,
    &::after {
      content: attr(title);
      position: absolute;
      left: 0;
    }
    &::before {
      animation: ${glitchTop} 1s linear infinite;
      clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    }
    &::after {
      animation: ${glitchBottom} 1.5s linear infinite;
      clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    }
  }
  small {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: calc(50% + 30px);
    span {
      margin-right: 1em;
    }
  }
`;

const Block = styled.div`
  margin-bottom: 20px;
  & > * {
    padding: 10px;
  }
  &.search {
    img {
      left: 20px;
    }
    input[type="text"] {
      max-width: 768px;
      width: 100%;
    }
  }
  &.category {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      list-style: none;
      li {
        border: 1px solid #e1e8ed;
        border-radius: 7px;
        text-align: center;
        width: calc(25% - 20px);
        @media (max-width: ${mediaM}) {
          margin-bottom: 20px;
          width: calc(50% - 10px);
        }
        a {
          color: inherit;
          display: block;
          padding: 0.5em;
        }
      }
    }
  }
`;

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const categories = data.site.siteMetadata.categories;
    const tags = data.allMarkdownRemark.group;
    const tagsList = [];

    tags.map((tag) => {
      tagsList.push(tag.tag);
      return "";
    });

    console.log(categories);

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Wrapper>
          <SEO
            pageTitle="ページが見つかりませんでした"
            pageDescription="そんなページはない"
            location={this.props.location}
          />
          <NotFoundImage
            style={{ backgroundImage: `url('/assets/noise.gif')` }}
          >
            <strong title="404">404</strong>
            <small>
              <span>縺昴?莉</span>
              <time>0o00-o0-00</time>
            </small>
          </NotFoundImage>
          <h1>ページが見つかりませんでした</h1>
          <Block className="search">
            <h2>
              <span role="img" aria-label="探すアイコン">
                🔍
              </span>
              検索から探す
            </h2>
            <Search />
          </Block>
          <Block className="category">
            <h2>
              <span role="img" aria-label="探すアイコン">
                📁
              </span>
              カテゴリから探す
            </h2>
            <ul>
              {categories.map((c) => (
                <li>
                  <Link to={`/category/${c.path}`}>{c.name}</Link>
                </li>
              ))}
            </ul>
          </Block>
          <Block className="tags">
            <h2>
              <span role="img" aria-label="探すアイコン">
                🏷
              </span>
              タグから探す
            </h2>
            <Tags tags={tagsList} />
          </Block>
        </Wrapper>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
        categories {
          name
          path
        }
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
      }
    }
  }
`;
