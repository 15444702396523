import React, { useState, useEffect } from "react";

import Seo from "../../components/Seo";
import Layout from "../../components/WhitehatSeo/Layout";
import Code from "../../components/WhitehatSeo/Code";

import styled from "styled-components";

const Bread = (props) => {
  // jsonデータ取得〜出力
  const [jsonLd, setJsonLd] = useState({});

  // 構造化データ作成
  useEffect(() => {
    setJsonLd({
      // "@context": "http://schema.org",
      // "@type": "VideoObject",
      // name: d.snippet.title,
      // description: d.snippet.description.replace(/\n/g, ""),
      // thumbnailUrl: d.snippet.thumbnails.default.url,
      // uploadDate: d.snippet.publishedAt,
      // duration: d.contentDetails.duration,
      // contentUrl: inputUrl,
      // interactionCount: d.statistics.viewCount,
    });
  }, []);

  return (
    <Layout id="recruitment" kind="求人" common={false} {...props}>
      <Seo
        pageTitle="求人の構造化データ作成ツール"
        pageDescription="求人の構造化データを簡単に作成するツールです。構造化データをコード内に挿入することでSEO効果や検索結果画面でのクリック率の上昇が見込めます。"
        location={props.location}
      />

      <p style={{ marginBlockEnd: "15px" }}>
        <span className="emoji" role="img">
          👇
        </span>
        下の入力欄を埋めていくと構造化データが生成されるよ
      </p>
      <Code jsonLd={jsonLd} />
    </Layout>
  );
};

export default Bread;
