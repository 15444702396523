// Ref: https://codepen.io/Shadowmaster_RA/pen/xxxXRKw
import React from "react";

import styled from "styled-components";

const RadioSwitch = styled.label`
  .switch[type="checkbox"] {
    -webkit-appearance: none;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    height: 30px;
    outline: none;
    position: relative;
    transition: 0.5s;
    width: 60px;
  }
  .switch:checked[type="checkbox"] {
    background-color: #55c501;
  }
  .switch[type="checkbox"]::before {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0.9);
    transition: 0.5s;
    width: 30px;
  }
  .switch:checked[type="checkbox"]::before {
    left: 30px;
  }
`;

const Switch = ({ title, onChange, checked = false }) => {
  return (
    <RadioSwitch>
      <span>{title}</span>
      <input
        type="checkbox"
        className="switch"
        onChange={onChange}
        defaultChecked={checked}
      />
    </RadioSwitch>
  );
};

export default Switch;
