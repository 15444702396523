import React from "react";

import styled from "styled-components";
import "typeface-quicksand";

const Wrap = styled.h2`
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  letter-spacing: 2px;
  margin-bottom: 100px;
  text-align: center;
`;

const Title = ({ title }) => <Wrap>{title}</Wrap>;

export default Title;
