// require("firebase/auth");
require("firebase/database");
require("firebase/firestore");
// require("firebase/functions");

exports.onRouteUpdate = ({ location }) => {
  if (
    location.pathname !== "/" &&
    location.pathname !== "/exam" &&
    location.pathname !== "/exam/" &&
    location.pathname !== "/portfolio" &&
    location.pathname !== "/portfolio/" &&
    location.pathname !== "/himatsubushi" &&
    location.pathname !== "/himatsubushi/"
  ) {
    console.log(location.pathname);
    createSwitch();
    switchFunc();
    smoothScroll();
    shareButtonShow();
    toggleFixedHeader();
  }

  if (
    location.pathname === "/portfolio" ||
    location.pathname === "/portfolio/"
  ) {
    portfolio();
  }
};

// functions

const createSwitch = () => {
  const targets = document.querySelectorAll(".gatsby-highlight"),
    insert =
      '<div class="toggleable__filter"></div><span class="toggleable__btn">全部見る</span>';
  if (!targets) return;
  targets.forEach((t) => {
    if (t.offsetHeight > 190) {
      t.insertAdjacentHTML("beforeEnd", insert);
    }
  });
};

const switchFunc = () => {
  const btns = document.querySelectorAll(".toggleable__btn");
  btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      btn.parentElement.classList.add("toggleable");
    });
  });
};

const smoothScroll = () => {
  const btn = document.querySelector("#goTop");
  if (!btn) return;
  btn.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("click");
  });
};

const shareButtonShow = () => {
  window.addEventListener("scroll", () => {
    const scrollY = window.pageYOffset,
      startTrigger = document.querySelector("#bio"),
      endTrigger = document.querySelector("#tags"),
      shares = document.querySelector("#sideShares"),
      goTop = document.querySelector("#goTop");

    if (!startTrigger || !endTrigger || !shares || !goTop) return;
    const shareShown = scrollY + startTrigger.getBoundingClientRect().top,
      shareUnshown =
        scrollY +
        endTrigger.getBoundingClientRect().bottom -
        window.innerHeight;

    if (shareUnshown > scrollY && scrollY > shareShown) {
      shares.classList.add("shown");
      goTop.classList.add("shown");
    } else if (scrollY > shareUnshown && scrollY > shareShown) {
      shares.classList.remove("shown");
      goTop.classList.remove("shown");
    } else {
      shares.classList.remove("shown");
      goTop.classList.remove("shown");
    }
  });
};

const toggleFixedHeader = () => {
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    const header = document.querySelector("#header"),
      hHeight = header.clientHeight;
    let pos,
      lastPos = 0;
    window.addEventListener("scroll", () => {
      pos = window.scrollY;
      if (pos > hHeight && pos > lastPos) {
        header.classList.add("hidden");
      }
      if (pos < hHeight || pos < lastPos) {
        header.classList.remove("hidden");
      }
      lastPos = pos;
    });
  }
};

const portfolio = () => {
  const works = document.querySelectorAll(".work__image");
  const options = {
    root: null,
    rootMargin: "20px",
    threshold: 0.4,
  };

  const observer = new IntersectionObserver(doWhenIntersect, options);

  works.forEach((work) => {
    observer.observe(work);
  });

  function doWhenIntersect(entries) {
    entries.forEach((e) => {
      if (e.isIntersecting) {
        activate(e.target);
      }
    });
  }

  function activate(e) {
    const currentActive = document.querySelector("#worksArea .active");
    if (currentActive !== null) {
      currentActive.classList.remove("active");
    }
    const newActive = document.querySelector(`#${e.id}Timeline`);
    newActive.classList.add("active");
  }
};
