import React from "react";

// COMPONENT
import Title from "./Title";
import Work from "./Work";
import Timeline from "./Timeline";

// CSS
import styled from "styled-components";

const Works = (props) => {
  const Wrap = styled.section`
    padding-top: 200px;
  `;

  const Flex = styled.div`
    display: flex;
    margin: auto;
    max-width: 1024px;
    width: 100%;
    > * {
      width: 100%;
      @media (min-width: 769px) {
        margin: 0 auto;
        width: calc(50% - 20px);
      }
    }
  `;

  const TimeLineWrap = styled.div`
    height: 100%;
    padding-right: 50px;
    position: sticky;
    top: 60px;
    @media (max-width: 768px) {
      display: none;
    }
  `;

  const WorkWrap = styled.div``;

  return (
    <Wrap>
      <Title title="All Works." />
      <Flex id="worksArea">
        <TimeLineWrap>
          {props.nodes.map((work, i) => (
            <Timeline {...work} key={i} index={i} />
          ))}
        </TimeLineWrap>
        <WorkWrap>
          {props.nodes.map((work, i) => (
            <Work {...work} key={i} index={i} />
          ))}
        </WorkWrap>
      </Flex>
    </Wrap>
  );
};

export default Works;
