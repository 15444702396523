import React from "react";

// STYLE
import Title from "./Title";
import styled from "styled-components";
import blockWrapperStyle from "../../styles/blockWrapper";

import Form from "../Form";

const Wrap = styled.section`
  ${blockWrapperStyle}
`;

const PortfolioForm = () => {
  return (
    <Wrap>
      <Title title="Get in touch" />
      <Form />
    </Wrap>
  );
};

export default PortfolioForm;
