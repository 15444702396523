import { css } from 'styled-components';

import iconToc from '../icon/toc.svg';

const postTocStyle = css`
&>h2:first-of-type{
  background: #e1e8ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: .25em .5em;
  position: relative;
  text-align: center;
  &::before{
    background-image: url(${ iconToc});
    background-size: contain;
    content: '';
    display: inline-block;
    height: 1.25em;
    margin-left: -1.5em;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 1.25em;
  }
}
&>ul:first-of-type{
  border: 1px solid #e1e8ed;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: .9em;
  list-style: none;
  margin: 0 0 30px;
  opacity: .9;
  padding: 15px;
  a{
    border-radius: 3px;
    color: #333;
    display: block;
    padding: .25em .5em;
    transition: .3s;
    &:hover{
      background: #efefef;
    }
  }
  p{
    margin: 0;
  }
}
`;

export default postTocStyle;
