import React from "react";
import { Link } from "gatsby";

import RemainDay from "./RemainDay";

import styled from "styled-components";

const container = (props) => props.theme.container.news;
const gapS = (props) => props.theme.gap.s;

const Wrap = styled.div`
  background: #222;
  font-size: 0.9em;
  a {
    color: #fff;
    display: block;
    margin: auto;
    max-width: ${container};
    padding: 0 ${gapS};
  }
`;

const News = () => (
  <Wrap>
    <a href="/teexam-study">▶︎ 基本情報技術頑張るぞい</a>
  </Wrap>
);

export default News;
