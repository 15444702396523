import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

const contentWidth = (props) => props.theme.container.content;
const gapS = (props) => props.theme.gap.s;
const gapM = (props) => props.theme.gap.m;

const Wrap = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
  padding: ${gapS} ${gapS};
`;

const Title = styled.h2`
  margin: ${gapS} 0;
  text-align: center;
`;
const SubTitle = styled.h3`
  margin: ${gapS} 0 ${gapM};
  text-align: center;
`;
const Loader = styled.div`
  .loader,
  &::after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
  .loader {
    margin: 60px auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.6em solid rgba(0, 0, 0, 0.2);
    border-right: 0.6em solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.6em solid rgba(0, 0, 0, 0.2);
    border-left: 0.6em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const MainArea = () => {
  const [minutes, setMinutes] = useState("");
  const [percentage, setPercentage] = useState("");
  const [sum, setSum] = useState([]);
  const [pacemaker, setPacemaker] = useState([]);

  const [loading, setLoading] = useState(false);

  const options = {
    chart: {
      type: "column",
      zoomType: "x",
      scrollablePlotArea: {
        minWidth: 800,
        scrollPositionX: 1,
      },
    },
    title: {
      text: "自分の合計勉強時間 と 合格者の平均勉強時間目安",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "年",
      },
    },
    yAxis: {
      title: {
        text: "勉強時間",
      },
    },
    plotOptions: {
      series: {
        pointInterval: 24 * 3600 * 2000, // 1日ごと
        pointStart: Date.UTC(2021, 9, 19),
      },
    },
    series: [
      {
        name: "合計勉強時間",
        data: sum,
      },
      {
        name: "合格へのペースメーカー",
        data: pacemaker,
      },
    ],
    scrollbar: {
      enabled: true,
    },
  };

  const setResponse = (data) => {
    const date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();
    const formatDate = year + "/" + month + "/" + day;
    console.log(formatDate)
    data.forEach((d, i) => {
      if (d.date === formatDate) {
        setMinutes(d.minutes);
        setPercentage(Math.floor(d.percentage * 100));
      }
      if ((i + 1) % 2 === 0) return;
      const copySum = sum;
      copySum.push(d.sum);
      setSum(copySum);

      const copyPacemaker = pacemaker;
      copyPacemaker.push(d.pacemaker);
      setPacemaker(copyPacemaker);
    });
    console.log(sum);
    console.log(pacemaker);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await fetch(
        "https://script.google.com/macros/s/AKfycbzeXN-crUGg02kCKgAkufdqySSmgJ4Ccee7wJbRk3tMF3wikdP4xFYacN3gJyMxFNLypg/exec"
      )
        .then((res) => res.json())
        .then((res) => {
          setResponse(res);
        });
      setLoading(false);
    }
    fetchData();
    return;
  }, []);

  return (
    <Wrap>
      <Title>基本情報技術者試験の勉強ログ</Title>
      {loading ? (
        <Loader>
          <div className="loader" />
        </Loader>
      ) : (
        <>
          <SubTitle>
            本日の勉強時間: {minutes}分<br />
            (合計勉強時間達成率: {percentage}%)
          </SubTitle>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </>
      )}
    </Wrap>
  );
};

export default MainArea;
