import React from "react";
import styled from "styled-components";
import "typeface-quicksand";
import Title from "./Title";

const Poem = () => {
  const Wrap = styled.section`
  `;

  const Inner = styled.div`
    margin: auto;
    max-width: 768px;
    padding: 80px 0;
    width: 95%;
  `;

  const Block = styled.div`
  p, strong{
    display: block;
    margin-top: 1em;
  }
  `;

  return (
    <Wrap>
      <Inner>
        <Block>
          <Title title="Poem." />
          <p>小学生の頃、掲示板にどハマりして自分でホムペを作ってみたり、中学生の頃にはインテリアに凝ってブログを運営してみたり……。幼い頃からモノヅクリドリブンなゆるーいWebとの繋がりを持っていました。</p>
          <p>大学に上がってからは塾講師をやってみたり輸入転売をしてみたり……。ちょうど将来に繋がる何かを探している頃にWeb制作と再会。</p>
          <p>普通、良質な情報ほどクローズドにされるのが常ですが、それを敢えてオープンにし、業界の発展を全員で目指していくエンジニアの文化に惚れ込みました。<small>（有用性はともかく、僕自身も得た知見を積極的に共有するようにしているのはここに起因しています。）</small></p>
          <p>しばらくはその楽しさからWeb制作業界に身を置いていたものの、徐々にWordPressでの表示速度に不満を持ち始め、そんな折にReact（Gatsby）と出会い、今（2022/02/06）ではJSに没頭する日々を過ごしています。</p>
          <strong>キャリアの主軸は「働きたくない人が無理に働かなくても良い世界の実現に貢献できるか」。</strong>
          <p>プログラムによる自動化をもって、人がやらなくても良いような仕事を少しでも減らしたいと考えています。それによって浮いた時間やお金を、人生を豊かにするために費やせるような未来の実現に貢献していくことが働くモチベーションです。</p>
        </Block>
      </Inner>
    </Wrap>
  );
};

export default Poem;
