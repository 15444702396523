import React, { useState, useEffect } from "react";

import styled from "styled-components";

import shuffle from "../../modules/shuffle";
import question from "../../icon/question.svg";


const ContentBackGround = () => {

  const Wrap = styled.header``;

  const CatchWrap = styled.div`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  background-blend-mode: screen;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 758px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    height:500px;
  }
  @media (min-width: 769px) {
    height: 500px;
  }
`;

  const Catch = styled.div`
  padding: 20px;
  position: relative;
  z-index: 1;
  @media (min-width: 769px) {
    padding: 20px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  h1 {
    font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体",
      "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", sans-serif;
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 25px;
    }
    @media (min-width: 769px) {
      font-size: 35px;
    }
  }
  p {
    font-size: 14px;
  }

  .only__tab {
    margin: 20px 0 0;
    text-align: center;

    span {
      color: #777;
    }

    .btn__howtouse {
      border: 2px solid #777;
      border-radius: 30px;
      color: #777;
      cursor: pointer;
      display: inline-block;
      padding: 3px 25px;
      position: relative;
      &::after {
        background: 0 0 / contain no-repeat url(${question});
        content: "";
        display: inline-block;
        height: 1.2em;
        line-height: 1;
        margin-left: 5px;
        vertical-align: middle;
        width: 1em;
      }
    }
  }

  @media (min-width: 981px) {
    .only__tab {
      display: none;
    }
  }
`;

  const HowTo = styled.div`
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: -100%;
  width: 100%;

  @media (min-width: 981px) {
    display: none;
  }

  &.shown {
    display: block;
    top: 0;
    z-index: 6;
  }

  .modal__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .modal__close {
    background: #444;
    bottom: 0;
    color: #fff;
    font-weight: bold;
    line-height: 80px;
    margin: auto;
    position: fixed;
    text-align: center;
    width: 100%;
  }
`;



  const [style, setStyle] = useState();
  const [open, setOpen] = useState();

  useEffect(() => {
    const backgroundColor = [
      "#ffe300",
      "#ff7e00",
      "#f04100",
      "#d100ff",
      "#3300ff",
      "#de3c3c",
      "#dece3c",
      "#57de3c",
      "#3c82de",
      "#a33cde",
    ];
    const backgroundColorRand = shuffle(backgroundColor);
    const colors = backgroundColorRand.slice(0, 3);

    setStyle({
      backgroundImage: `url("/assets/himatsubushi/bk.png"),linear-gradient(340deg,${colors.map(
        (c) => c
      )})`,
    });
  }, []);

  return (
    <Wrap>
      <CatchWrap style={style}>
        <Catch>
          <h1>
            世界はこんなにも
            <br className="pc__none" />
            楽しいことで満ちている
          </h1>
          <p>
            暇な時間を潰すのに打ってつけの暇つぶしを大量に揃えました。コロナ禍で退屈している人、ドライブや仕事中に暇な時間がある人、待ち時間を潰したい人。全ての暇な人が楽しく時間を潰せる手段を見つけられれば幸いです。
          </p>
          <div className="only__tab">
            <p
              className="btn__howtouse"
              onClick={() => {
                setOpen(true);
              }}
            >
              使い方を見る
            </p>
          </div>
        </Catch>
      </CatchWrap>
      <HowTo className={`only__tab ${open ? "shown" : ""}`}>
        <div className="modal__container">
          <p>HOWTO</p>
          <button
            className="modal__close"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </HowTo>
    </Wrap>
  );
};

export default ContentBackGround;
