import React from "react";
import { Link } from "gatsby";

import Adsense from "./Adsense";

import styled from "styled-components";
import "typeface-quicksand";

import saiIcon from "../icon/sai.svg";
import pinataIcon from "../icon/categoryIcon/pinata.svg";
import whitehatIcon from "../icon/whitehatseotools/logo.svg";

const sideWidth = (props) => props.theme.container.side;
const borderColor = (props) => props.theme.color.border;
const radiusS = (props) => props.theme.radius.s;
const gapS = (props) => props.theme.gap.s;
const headerHeight = (props) => props.theme.headerHeight;
const quickSand = (props) => props.theme.font.quickSand;

const Wrap = styled.aside`
  margin: 0 auto;
  max-width: ${sideWidth};
  overflow: visible;
  width: 100%;
`;

const Stickies = styled.div`
  @media (min-width: 1029px) {
    position: sticky;
    top: calc(${headerHeight} + ${gapS});
  }
`;

const AdsenseWrap = styled.div`
  margin-block-end: ${gapS};

  &:nth-of-type(1) {
    margin-block-start: ${gapS};
  }
`;

const Widget = styled.div`
  border: 1px solid ${borderColor};
  border-radius: ${radiusS};
  /* box-shadow: 0 3px 10px -3px hsl(0 0% 0% / 20%); */
  margin-block-end: ${gapS};

  &:nth-of-type(1) {
    margin-block-start: ${gapS};
  }
`;

const Profile = styled.div`
  padding: ${gapS};
  text-align: center;
  img {
    background: ${borderColor};
    border-radius: 50%;
    display: block;
    height: 100px;
    margin: 0 auto ${gapS};
    padding: 10px;
    width: 100px;
  }
  .name {
    border-block-end: 2px solid ${borderColor};
    display: inline-block;
    font-weight: bold;
    margin-block-end: calc(${gapS} / 2);
    padding-block-end: calc(${gapS} / 2);
  }
  .description {
    font-size: 0.9em;
    text-align: left;
    word-break: break-all;
  }
`;

const Banner = styled.div`
  background: ${borderColor};
  height: 100px;
  overflow: hidden;
  padding: ${gapS};

  p {
    color: #666;
    font: 2em ${quickSand};
    line-height: calc(100px - ${gapS} - ${gapS});
    text-align: right;
  }

  &.works,
  &.tools {
    background-blend-mode: lighten;
    background-color: rgba(225, 232, 237, 0.8);
    background-size: 150px 150px;
    background-repeat: no-repeat;
  }
  &.works {
    background-image: url(${pinataIcon});
  }
  &.tools {
    background-image: url(${whitehatIcon});
  }
`;

const Aside = (props) => {
  console.log(props.location);
  return (
    <Wrap>
      <AdsenseWrap>
        <Adsense path={props.location.pathname} />
      </AdsenseWrap>
      <Widget>
        <Profile>
          <img src={saiIcon} alt="クロサイ" />
          <p className="name">クロサイ</p>
          <p className="description">
            個人開発が好きなWebエンジニア(H6年生)。ブログ4年目に突入(2018/09~)しました。過去のブログは数十万で売却済み。当ブログは月間5万PVくらい。前は池袋でメディアの運用/保守/開発/SEO・MEO施策などのWebマスター的なことしてました。今は神田で自社製品の開発に携わってます。仕事ではPHP,React,Vue, 趣味ではGatsby,Next,Node,稀にRailsを書いてます
          </p>
        </Profile>
      </Widget>
      <Stickies>
        <Widget>
          <Link to="/portfolio">
            <Banner className="works">
              <p>Works</p>
            </Banner>
          </Link>
        </Widget>
        <Widget>
          <Link to="/whitehat-seo-tools">
            <Banner className="tools">
              <p>SEO Tools</p>
            </Banner>
          </Link>
        </Widget>
        <Adsense path={props.location.pathname} />
      </Stickies>
    </Wrap>
  );
};

export default Aside;
