import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";

import logo from "../../icon/logo_light.svg";

const Wrap = styled.div`
  bottom: 25px;
  display: grid;
  gap: 5px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  span {
    color: #cfcfcf;
    font-size: 12px;
  }
  img {
    width: 90px;
  }
`;

const Author = () => {
  return (
    <Wrap>
      <span>Powered by</span>
      <Link to="/">
        <img src={logo} alt="サイナビ ロゴ" />
      </Link>
    </Wrap>
  );
};

export default Author;
