import React, { useState } from "react";

// COMPONENT
import Canvas from "./Canvas";
import CanvasController from "./CanvasController";

// CSS
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  gap: 20px;
`;

const CanvasArea = ({ siteTitle, path }) => {
  const [state, setState] = useState({
    text: "",
    backgroundColor: "#222",
    darkmode: true,
    terminal: true,
  });

  const handle = (state) => {
    setState(state);
  };

  return (
    <Wrap>
      <Canvas {...state} title={siteTitle} path={path} />
      <CanvasController
        state={state}
        handle={(state) => handle(state)}
        path={path}
      />
    </Wrap>
  );
};

export default CanvasArea;
