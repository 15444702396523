import React from "react";
import { graphql } from "gatsby";

//COMPONENTS
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import PostLink from "../components/PostCard";
import PostContainer from "../components/PostsContainer";

import styled from "styled-components";
import blockWrapperStyle from "../styles/blockWrapper";

const TagTitle = styled.h1`
  ${blockWrapperStyle}
  font-size: 1.4em;
  b {
    border: 2px solid #e1e8ed;
    border-radius: 3px;
    margin-right: 0.5em;
    padding: 0.25em 0.5em;
    &::before {
      color: #999;
      content: "#";
      margin-right: 0.25em;
    }
  }
`;

const TagTemplate = (props) => {
  const { data, pageContext } = props;
  const post = data.allMarkdownRemark.edges;
  const siteTitle = props.data.site.siteMetadata.title;

  const tagName = pageContext.tag;

  return (
    <Layout title={siteTitle} location={props.location}>
      <Seo pageTitle={tagName + "の記事一覧"} location={props.location} />
      <TagTitle>
        <b>{tagName}</b>
        <span>の記事</span>
      </TagTitle>
      <PostContainer>
        {post.map((edge) => {
          return <PostLink key={edge.node.id} post={edge.node} />;
        })}
      </PostContainer>
    </Layout>
  );
};

export default TagTemplate;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        categories {
          name
          path
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { tags: { eq: $tag } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            path
            title
            category
            tags
            thumbnail
          }
        }
      }
    }
  }
`;
