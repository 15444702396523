import React from "react";

import styled from "styled-components";

import Menu from "./Menu";
import Author from "./Author";

const contentWidth = props => props.theme.container.content
const gapS = props => props.theme.gap.s
const mainWidth = props => props.theme.container.main

const Wrap = styled.aside`
  width: calc(${contentWidth} - ${gapS} - ${gapS} - ${mainWidth} - 1px - 1px);
  @media (max-width: 980px) {
    display: none;
  }

  h3 {
    display: flex;
    margin-bottom: 50px;
    span {
      font: 25px "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体",
        "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", sans-serif;
      margin-right: 10px;
    }
    img {
      height: 40px;
    }
  }
`;

const Container = styled.div`
  height: 100%;
  max-width: calc(${contentWidth} - ${gapS} - ${gapS} - ${mainWidth} - 1px - 1px);
  -ms-overflow-style: none;
  position: fixed;
  padding: 20px 10px;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Sidebar = (props) => (
  <Wrap>
    <Container>
      <h3>
        <span>ヒマツ武士</span>
        <img src="/assets/himatsubushi/bushi.png" alt="ロゴ" />
      </h3>
      <Menu {...props} />
      <Author />
    </Container>
  </Wrap>
);

export default Sidebar;
