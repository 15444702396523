import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import blockWrapper from "../styles/blockWrapper";

const TagsLists = styled.div`
  ${blockWrapper}
  font-size: .8em;
  li {
    border: 1px solid #e1e8ed;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.19);
    display: inline-block;
    padding: 0.25em 0.5em;
    transition: 0.3s;
    &:not(:last-child) {
      margin: 0 1em 1em 0;
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
      transform: translateY(-2px);
    }
    a {
      color: #2f353d;
      display: block;
      &::before {
        color: #999;
        content: "#";
        margin-right: 0.25em;
      }
    }
  }
`;

const Tag = ({ tag }) => {
  return (
    <li>
      <Link to={`/tag/${tag}`}>{tag}</Link>
    </li>
  );
};

const Tags = ({ tags }) => {
  if (!tags.length) return null;
  let lists = [];

  tags.forEach((tag) => {
    lists.push(<Tag key={tag} tag={tag} />);
  });
  return (
    <TagsLists id="tags">
      <ul>{lists}</ul>
    </TagsLists>
  );
};

export default Tags;
