import React from "react";

import styled from "styled-components";
import { saturate } from "polished";

const Wrap = styled.label`
  align-items: center;
  border: solid 1px #e1e8ed;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 0.5em 1em;

  .switch[type="checkbox"] {
    -webkit-appearance: none;
    border: 1px solid #ccc;
    border-radius: 30px;
    cursor: pointer;
    height: 15px;
    outline: none;
    position: relative;
    transition: 0.5s;
    width: 30px;
  }
  .switch:checked[type="checkbox"] {
    background-color: #69e083;
    border-color: #ccc;
  }
  .switch[type="checkbox"]::before {
    background: #ccc;
    border-radius: 50%;
    content: "";
    height: 15px;
    left: -2px;
    position: absolute;
    top: -1px;
    transform: scale(0.6);
    transition: 0.5s;
    width: 15px;
  }
  .switch:checked[type="checkbox"]::before {
    background: #fff;
    left: 14px;
  }
`;

const SwitchBar = ({ title, onChange, state }) => {
  return (
    <Wrap>
      <span>{title}</span>
      <input
        type="checkbox"
        className="switch"
        checked={state.indexOf(title) !== -1 ? true : false}
        onChange={onChange}
      />
    </Wrap>
  );
};

export default SwitchBar;
