import React, { useState, useEffect } from "react";
import axios from "axios";

import Seo from "../../components/Seo";
import Layout from "../../components/WhitehatSeo/Layout";
import Code from "../../components/WhitehatSeo/Code";

import styled from "styled-components";
import fadeIn from "../../styles/animation/fadeIn";

import loadingIcon from "../../icon/loading.svg";

import prefectures from "../../modules/prefecture";

const gapS = (props) => props.theme.gap.s;
const mediaS = (props) => props.theme.media.s;

const Heading = styled.h3`
  font-size: 1.2em;
  margin-block-end: calc(${gapS} / 2);
  padding-inline-start: 25px;
  position: relative;
  &::before {
    content: "📍";
    display: inline-block;
    left: 0;
    position: absolute;
  }
`;

const Input = styled.input`
  background: #fff;
  font-size: 16px;
  margin-block-end: ${gapS};
  line-height: 1;
  padding: 10px 15px;
  width: 100%;
  &:focus + .tooltip {
    animation: ${fadeIn} 0.5s forwards;
  }
`;

const TooltipWrap = styled.div`
  position: relative;
  .tooltip {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: block;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    padding: 6px;
    position: absolute;
    top: calc(-16px + -6px * 2);
    transform: translateY(10px);
    z-index: 2;
  }
`;

const Select = styled.select`
  background: #fff;
  border: none;
  font-size: 16px;
  line-height: 1;
  margin-block-end: ${gapS};
  padding: 10px 15px;
  width: 100%;
`;

const AnimationWrap = styled.div`
  animation: ${fadeIn} 1s forwards;
  opacity: 0;
  transform: translateY(80px);
`;

const InputAddressWrap = styled.div`
  display: grid;
  gap: ${gapS};
  margin-block-end: ${gapS};
  @media (min-width: ${mediaS}) {
    grid-template-columns: 100px 200px 1fr;
  }
  input,
  select {
    margin-block-end: 0;
  }
`;

const LoadingWrap = styled.div`
  display: grid;
  height: 300px;
  margin: 100px auto;
  place-items: center;
  width: 300px;
  img {
    height: 50px;
    width: 50px;
  }
`;

const LocalBusiness = (props) => {
  // json用
  const [postalcode, setPostalcode] = useState("");
  const [address, setAddress] = useState({
    region: "",
    locality: "",
    address: "",
  });
  const [time, setTime] = useState("");

  // UI用
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // jsonデータ取得〜出力
  const [jsonLd, setJsonLd] = useState({});

  const handlePostalCode = (e) => {
    setPostalcode(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (postalcode.length === 7) {
        const result = await axios(
          `https://madefor.github.io/postal-code-api/api/v1/${postalcode.slice(
            0,
            3
          )}/${postalcode.slice(-4)}.json`
        );
        setAddress({
          region: result.data.data[0].ja.prefecture,
          locality: result.data.data[0].ja.address1,
          address: result.data.data[0].ja.address2,
        });
      }
    };
    fetchData();
    setIsLoading(false);
  }, [postalcode]);

  // 構造化データ作成
  useEffect(() => {
    setJsonLd({
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: "サンプルストア渋谷店", //事業者名
      description: "サンプルストア渋谷店は渋谷のサンプルショップです。", //事業所の説明
      telephone: "000-000-0000", //電話番号
      faxNumber: "000-000-0000", //FAX番号
      address: {
        "@type": "PostalAddress",
        postalCode: "000-0000", //郵便番号
        addressRegion: " 東京都", //件名
        addressLocality: "渋谷区", //市区町村
        streetAddress:
          "渋谷1丁目17番2号　ヒューリック渋谷宮下公園ビル 7F(受付)・8F", //番地以下
      },
      geo: "35.6672704,139.7133435", //所在地の座標
      logo: "http://www.example.com/logo.jpg", //ロゴ画像
      brand: "サンプルストア", //ブランド名
      department: "サンプルモール北館2F",
      alternateName: "サンスト", //事業所の別名・愛称等
      url: "http://www.example.com/", //WebサイトのURL
      sameAs: "https://twitter.com/", //twitter,Facebook等
      photo: "http://www.example.com/photo.jpg", //事業所の写真
      openingHoursSpecification: [
        //詳細な営業時間
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            //営業している曜日
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
          ],
          opens: "9:00", //営業開始時間
          closes: "20:00", //営業終了時間
        },
        {
          // 上の営業時間以外の営業時間
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            //営業している曜日
            "Saturday",
            "Sunday",
          ],
          opens: "9:00", //営業開始時間
          closes: "18:00", //営業終了時間
        },
      ],
    });
  }, []);

  return (
    <Layout
      id="local-business"
      kind="ローカルビジネス"
      common={false}
      {...props}
    >
      <Seo
        pageTitle="ローカルビジネスの構造化データ作成ツール"
        pageDescription="ローカルビジネスの構造化データを簡単に作成するツールです。構造化データをコード内に挿入することでSEO効果や検索結果画面でのクリック率の上昇が見込めます。"
        location={props.location}
      />
      <p style={{ marginBlockEnd: "15px" }}>
        <span className="emoji" role="img">
          👇
        </span>
        下の入力欄を埋めていくと構造化データが生成されるよ
      </p>
      <Heading className="postalcode">お店の住所</Heading>
      {/* 郵便番号 */}
      <TooltipWrap>
        <Input
          type="number"
          placeholder="郵便番号（半角数字7桁）"
          name="postalcode"
          value={postalcode}
          onChange={handlePostalCode}
        />
        <div className="tooltip">ハイフン不要。7桁の数字で入力してね</div>
      </TooltipWrap>
      {/* 都道府県 */}
      {address.region && (
        <AnimationWrap>
          <InputAddressWrap>
            <Select
              placeholder="都道府県"
              name="region"
              value={address.region}
              onChange={handleAddress}
            >
              <option value="" defaultValue disabled>
                都道府県
              </option>
              {prefectures.map((prefecture) => (
                <option key={prefecture} value={prefecture}>
                  {prefecture}
                </option>
              ))}
            </Select>
            <Input
              type="text"
              placeholder="市町村区"
              name="locality"
              value={address.locality}
              onChange={handleAddress}
            />
            <TooltipWrap>
              <Input
                type="text"
                placeholder="市町村区以降"
                name="address"
                value={address.address}
                onChange={handleAddress}
              />
              <div className="tooltip">
                例: 渋谷1丁目17番2号　ヒューリック渋谷宮下公園ビル 7F(受付)・8F
              </div>
            </TooltipWrap>
          </InputAddressWrap>
        </AnimationWrap>
      )}
      {/* 電話番号 */}

      {isLoading && (
        <LoadingWrap>
          <img src={loadingIcon} />
        </LoadingWrap>
      )}
      <Code jsonLd={jsonLd} />
    </Layout>
  );
};

export default LocalBusiness;
