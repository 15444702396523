import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../styles/games/style.css";

import Slick from "../components/Games/Sliders/Slick";

const Games = () => {
  const [isStart, setIsStart] = useState(false);

  const card = {};

  return (
    <div className="wrap">
      {!isStart && (
        <div>
          <button onClick={() => setIsStart(true)}>GAME START</button>
        </div>
      )}
      {!!isStart && (
        <div className="slick__wrap">
          <Slick />
        </div>
      )}
    </div>
  );
};

export default Games;
